import React, { useState } from 'react'
import { useRedirect, Form } from 'react-admin'
import { Divider, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { connect } from 'react-redux'
import { PageTitle } from '../../../common'
import TabsBar from '../../../TabsBar'
import PartnerSubmissionForm from '../Edit/PartnerSubmissionForm'
import PandiForm from '../../themeConfig/PandiForm'
import { nameDetails, externalConfigs } from '../IntegrationCreateEditSchemas'
import { CreateButton } from '../../../Wizard/WizardFormButtons'
import { useFormContext } from 'react-hook-form'
import { get, isEmpty } from 'lodash-es'

const useButtonsStyles = makeStyles({
    buttons: {
        '& > button': {
            width: '165px',
            marginRight: '10px',
            lineHeight: '15px',
        },
    },
})

const Buttons = ({
    tabValue,
    setTabValue,
    isLastTab,
    isParentOrg,
    partnerFormErrors,
}) => {
    const classes = useButtonsStyles()
    const redirect = useRedirect()
    const form = useFormContext()
    const values = form.getValues()
    //todo: remove lodash and test
    const formError =
        !isEmpty(get(form, 'formState.errors')) ||
        !get(values, 'name', false) ||
        !get(values, 'longName', false) ||
        !get(values, 'marketplaceSettings.externalURL', false)

    return (
        <div className={classes.buttons}>
            <Button
                onClick={() => {
                    tabValue === 0 && redirect(`/integrations`)
                    tabValue === 1 && setTabValue(tabValue - 1)
                }}
            >
                {tabValue === 0 && 'CANCEL'}
                {tabValue === 1 && 'BACK'}
            </Button>
            {isLastTab ? (
                <CreateButton
                    resource="integrations"
                    values={values}
                    shouldRedirect={true}
                    disabled={formError}
                    text="SAVE"
                />
            ) : (
                <Button
                    variant="contained"
                    onClick={() => setTabValue(tabValue + 1)}
                    disabled={formError}
                >
                    NEXT
                </Button>
            )}
            {isLastTab && !isParentOrg && (
                <CreateButton
                    resource="integrations"
                    values={{
                        ...values,
                        approvalStatus: 'Submitted',
                    }}
                    shouldRedirect={true}
                    disabled={
                        formError ||
                        !values?.discussion?.enableSubmit ||
                        !isEmpty(partnerFormErrors)
                    }
                    text="SAVE AND SUBMIT"
                />
            )}
        </div>
    )
}

const useExternalIntegrationCreateStyles = makeStyles({
    divider: {
        marginTop: '25px',
        marginBottom: '25px',
    },
})

const ExternalIntegrationCreate = ({
    isParentOrg,
    showCustomSubmissionForm,
    orgName,
}) => {
    const classes = useExternalIntegrationCreateStyles()
    const [tabValue, setTabValue] = useState(0)
    const [initialValues, setInitialValues] = useState({
        type: 'External',
        approvalStatus: isParentOrg ? 'Approved' : 'Draft',
        marketplaceSettings: { published: false },
        discussion: {
            partnerName: orgName,
            enableSubmit: !showCustomSubmissionForm,
        },
    })
    const isLastTab = showCustomSubmissionForm ? tabValue === 1 : true
    const tabs = ['Configure', 'Submit for Review']
    const [partnerFormErrors, setPartnerFormErrors] = useState([])
    return (
        <div aria-label={'externalIntegrationCreate'}>
            <PageTitle title="New External Integration" />
            <Divider className={classes.divider} />
            {showCustomSubmissionForm && (
                <TabsBar
                    tabs={tabs}
                    // we want this to be a wizard, so tabs are not clickable
                    handleChange={null}
                    tabValue={tabValue}
                />
            )}
            <Form defaultValues={initialValues} mode="onChange">
                <>
                    {tabs[tabValue] === 'Configure' && (
                        <>
                            <PandiForm
                                schema={[...nameDetails, ...externalConfigs]}
                            />
                        </>
                    )}
                    {tabs[tabValue] === 'Submit for Review' && (
                        <PartnerSubmissionForm
                            setValues={setInitialValues}
                            values={initialValues}
                            readOnly={false}
                            errors={partnerFormErrors}
                            setErrors={setPartnerFormErrors}
                        />
                    )}
                    <Divider className={classes.divider} />
                    <Buttons
                        tabValue={tabValue}
                        setTabValue={setTabValue}
                        isLastTab={isLastTab}
                        isParentOrg={isParentOrg}
                        partnerFormErrors={partnerFormErrors}
                    />
                </>
            </Form>
        </div>
    )
}

const mapStateToProps = (state) => ({
    isParentOrg: state.org.isParentOrg,
    orgName: state.org.name,
    showCustomSubmissionForm:
        Boolean(state.org.customSubmissionForm) && !state.org.isParentOrg,
})

export default connect(mapStateToProps)(ExternalIntegrationCreate)
