import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material'
import { useRedirect, useDelete, useNotify } from 'react-admin'

export default function DeleteWithConfirmButton({
    resource,
    id,
    label,
    confirmContent,
    confirmTitle,
    toastContent,
}) {
    const redirect = useRedirect()
    const notify = useNotify()
    const [deleteOne, { loading }] = useDelete(
        resource,
        { id },
        {
            onSuccess: () => {
                notify(toastContent)
                redirect('list', resource)
            },
        }
    )
    const [open, setOpen] = useState(false)
    return (
        <>
            <Button
                variant="contained"
                color="error"
                onClick={() => setOpen(true)}
            >
                {label}
            </Button>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>{confirmTitle}</DialogTitle>
                <DialogContent>{confirmContent}</DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button
                        variant="contained"
                        disabled={loading}
                        onClick={(e) => deleteOne()}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
