import React, { useState, useEffect, Fragment } from 'react'
import {
    SimpleShowLayout,
    TextField,
    useRedirect,
    DateField,
    BooleanField,
    useGetOne,
    useGetList,
    useUpdate,
    useNotify,
    useUnselectAll,
    BulkDeleteButton,
    useDataProvider,
    TextInput,
} from 'react-admin'
import { useNavigate, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    Box,
    Button,
    Card,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    Tooltip,
} from '@mui/material'
import { Public } from '@mui/icons-material'
import {
    CustomDivider,
    HorizontalTextField,
    HorizontalScheduleField,
    RunPhaseField,
    useConnectorsList,
    CustomerField,
    useConnectors,
} from '../../common'
import { trimEnvPrefix, Capitalize } from '@thefront/pandipackV2'
import { CustomLabel } from '../../CustomLabel'
import { isEmpty, get } from 'lodash-es'
import BaseShow from '../Show/BaseShow'
import BaseTopToolbar from '../../react_admin/BaseTopToolbar'
import BaseDatagrid from '../../react_admin/BaseDatagrid'
import TenantBulkActionsButtons from '../List/BulkEdit/TenantBulkActionButtons'
import BaseList from '../List/BaseList'
import TabsBar from '../../TabsBar'
import { SUBGRID_RECORDS_PER_PAGE } from '../../../appConfigs'
import { EmptyState } from '../../react_admin/EmptyState'
import BaseDialog from '../../BaseDialog'
import SetupRepoDialog from '../../SetupRepoDialog'
import SyncButton from '../../SyncButton'
import { getRepoType } from '../accounts/SourceControlForm'
import { required } from '../../inputValidators'
import ConnectButton from '../../Auth/SimpleConnectorButton'
import RunBulkActionButtons from '../List/BulkEdit/RunBulkActionButtons'

const integrationShowStyles = {
    boldLarge: {
        fontFamily: 'RobotoCondensedBold',
        fontSize: '20px',
        paddingBottom: '5px',
        paddingTop: '5px',
    },
    firstCard: {
        display: 'flex',
        flexDirection: 'row',
        width: '95%',
        justifyContent: 'space-around',
        boxShadow: 'none',
        flex: '1',
        borderRadius: '0',
        margin: '0px 0 30px 0',
        padding: '10px 50px 10px 50px',
        borderBottom: '1px solid #D8D8D8',
    },
    flex1: {
        flex: '1',
        padding: '3px',
    },
    flex2: {
        flex: '2',
    },
    connectorsBox: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    connector: {
        flex: '1',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'flex-start',
    },
    connectorName: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    connectorLogo: {
        height: '40px',
        width: '40px',
        margin: '0 5px',
        objectContain: 'cover',
    },
    globalIcon: {
        ml: '5px',
    },
    regLarge: {
        color: '#6d6d6d',
        fontSize: '20px',
        marginBottom: '10px',
    },
}

const IntegrationTopToolBar = ({
    integration,
    integrationReleases,
    isParentOrg,
}) => {
    const redirect = useRedirect()
    const notify = useNotify()
    const navigate = useNavigate()

    const { approvalStatus, id, discussion } = integration
    const newStatus =
        isParentOrg || approvalStatus === 'Submitted' ? 'Approved' : 'Submitted'
    const newData = { approvalStatus: newStatus }
    if (newStatus === 'Submitted') {
        newData.submittedDate = new Date()
    }

    const [unarchiveIntegration] = useUpdate('integrations', {
        id: integration.id,
        data: { ...integration, archived: false },
        previousData: integration,
    })

    const [update, { isLoading: updateLoading }] = useUpdate(
        'integrations',
        {
            id: id,
            data: newData,
            previousData: integration,
        },
        {
            onSuccess: () => {
                notify(`Integration ${newStatus}`)
                if (newStatus === 'Submitted') {
                    redirect('/integrations')
                }
            },
            onError: (error) => {
                notify('Something went wrong!')
                console.debug('Error: ', get(error, 'message', error))
            },
        }
    )

    const buttons = [
        {
            label: 'CONFIGURE',
            onClick: () =>
                redirect(`/integrations/${integration.id}/edit/configure`),
            internalOnly: false,
        },
        {
            label: 'MARKETPLACE SETUP',
            onClick: () =>
                redirect(`/integrations/${integration.id}/edit/marketplace`),
            internalOnly: false,
        },
        {
            label: 'REPROVISION CONNECTORS',
            onClick: () =>
                redirect(`/integrations/${integration.id}/edit/connection`),
            internalOnly: true,
        },
    ]

    if (approvalStatus === 'Approved') {
        buttons.push({
            disabled: isEmpty(Object.keys(integrationReleases)),
            label: 'CREATE TENANT',
            onClick: () =>
                navigate({
                    pathname: '/tenants/create',
                    search: `integration_id=${integration.id}`,
                }),
            variant: 'action',
            internalOnly: true,
        })
    } else if (!isParentOrg) {
        if (['Draft', 'Reviewed'].includes(approvalStatus)) {
            buttons.push({
                disabled: updateLoading || !discussion.enableSubmit,
                label: 'SUBMIT FOR REVIEW',
                onClick: () => update(),
                variant: 'action',
                internalOnly: false,
            })
        }
    } else if (['Draft', 'Submitted'].includes(approvalStatus)) {
        buttons.push({
            disabled: updateLoading,
            label: 'APPROVE',
            onClick: () => update(),
            variant: 'action',
            internalOnly: false,
        })
    }

    return (
        <BaseTopToolbar
            pageTitle={integration.longName}
            handleBack={() => redirect('/integrations')}
            backButtonText="All Integrations"
        >
            {integration.archived ? (
                <Button onClick={() => unarchiveIntegration()}>
                    Unarchive Integration
                </Button>
            ) : (
                buttons.map((button) => {
                    // tooltip on create tenant button when there are no tenants
                    const showTooltip =
                        button.label === 'CREATE TENANT' && button.disabled
                    if (
                        !button.internalOnly ||
                        ['Internal', 'Pandium'].includes(integration.type)
                    ) {
                        return showTooltip ? (
                            <Tooltip
                                key={button.label}
                                // tooltip sx prop is buggy, this is a workaround. see here: https://github.com/mui/material-ui/issues/28679
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            fontFamily: 'Roboto',
                                            textAlign: 'center',
                                            width: '220px',
                                            height: 'auto',
                                            padding: '12px 10px',
                                            fontSize: '12px',
                                            backgroundColor: 'rgba(0,0,0,0.87)',
                                            mt: '40px',
                                            mr: '14px',
                                        },
                                    },
                                }}
                                placement="bottom-end"
                                title="Integration release required to create a tenant. Please push integration code to your repository to continue."
                            >
                                <span>
                                    <Button
                                        variant={button.variant ?? 'text'}
                                        onClick={button.onClick}
                                        disabled={button.disabled}
                                    >
                                        {' ' + button.label + ' '}
                                    </Button>
                                </span>
                            </Tooltip>
                        ) : (
                            <Button
                                variant={button.variant ?? 'text'}
                                onClick={button.onClick}
                                key={button.label}
                                disabled={button.disabled}
                            >
                                {' ' + button.label + ' '}
                            </Button>
                        )
                    } else {
                        return null
                    }
                })
            )}
        </BaseTopToolbar>
    )
}

// mapping tab value to display text
const EmptyStateText = {
    0: 'View and manage runs here. Create a tenant to get started.',
    1: 'View and manage tenants here. Create a tenant to get started.',
    2: 'View and manage integration releases here. Create an integration release to get started.',
    //if adding new tab, move the below to unused tabValue and change in RunDatagrid
    3: 'No runs yet.',
}

const RunDataGrid = ({ filter, perPageCustom }) => (
    <BaseList
        resource={'runs'}
        perPage={perPageCustom}
        sort={{ field: 'startedDate', order: 'DESC' }}
        filter={filter}
        changeItemsPerPage={true}
        perPageCustom={perPageCustom}
    >
        {
            <BaseDatagrid
                empty={<EmptyState emptyStateText={EmptyStateText[2]} />}
                rowClick="show"
                bulkActionButtons={<RunBulkActionButtons />}
            >
                <TextField source="id" label="ID" />
                <TextField source="mode" label="MODE" />
                <TextField source="jobSpec.tenant.name" label="TENANT" />
                <DateField source="startedDate" label="STARTED" showTime />
                <DateField source="completedDate" label="COMPLETED" showTime />
                <RunPhaseField source="status.phase" label="STATUS" />
            </BaseDatagrid>
        }
    </BaseList>
)

const TenantDataGrid = ({
    userInfo,
    accountConnector,
    namespace,
    ...props
}) => {
    const account = trimEnvPrefix(namespace)
    const uidDisplay = get(
        accountConnector,
        'metadata.user_identifier.display',
        ''
    )
    const customerColumnPath = `connectedUsers.${account}.attributes.${account}.${uidDisplay}`

    return (
        <BaseDatagrid
            rowClick="show"
            empty={<EmptyState emptyStateText={EmptyStateText[1]} />}
            {...props}
        >
            <TextField source="name" label={'NAME'} sortable={true} />
            {!isEmpty(uidDisplay) ? (
                <CustomerField
                    source={customerColumnPath}
                    label={'CUSTOMER'}
                    sortable={false}
                />
            ) : (
                <></>
            )}
            <DateField
                source="createdDate"
                label={'CREATED DATE'}
                sortable={true}
                sortByOrder="DESC"
            />
            <TextField
                label={'INTEGRATION RELEASE'}
                source="integrationRelease.name"
                sortable={true}
                sortByOrder="DESC"
            />
            <DateField
                source="status.lastRun.completionTime"
                label={'LAST RUN'}
                showTime={true}
                sortable={true}
                sortBy="tenant_status.last_run.completionTime"
                sortByOrder="DESC"
            />
            <BooleanField source="paused" label="PAUSED" sortable={true} />
        </BaseDatagrid>
    )
}

const ReleaseDataGrid = ({ filter, perPageCustom }) => (
    <BaseList
        resource={'integrationreleases'}
        perPage={perPageCustom}
        filter={filter}
        sort={{ field: 'createdDate', order: 'DESC' }}
        changeItemsPerPage={true}
        perPageCustom={perPageCustom}
    >
        {
            <BaseDatagrid
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
                empty={<EmptyState emptyStateText={EmptyStateText[2]} />}
            >
                <TextField source="name" label="RELEASE" sortable={true} />
                <TextField source="image" label="IMAGE" />
                <TextField source="tag" label="TAG" sortable={true} />
                <TextField
                    source="repositoryTrackingBranch"
                    label="REPOSITORY TRACKING BRANCH"
                />
                <DateField
                    source="createdDate"
                    label="CREATED DATE"
                    sortable={true}
                />
            </BaseDatagrid>
        }
    </BaseList>
)

const TenantList = ({
    filter,
    namespace,
    accountConnector,
    integration,
    perPageCustom,
}) => (
    <BaseList
        resource={'tenants'}
        perPage={perPageCustom}
        sort={{ field: 'createdDate', order: 'DESC' }}
        filter={filter}
        changeItemsPerPage={true}
        perPageCustom={perPageCustom}
    >
        {
            <TenantDataGrid
                namespace={namespace}
                accountConnector={accountConnector}
                bulkActionButtons={
                    <TenantBulkActionsButtons
                        resource="tenants"
                        integration={integration}
                    />
                }
            />
        }
    </BaseList>
)

const buildDialogInputFields = (integration, setReleaseTag) => (
    <>
        <TextInput
            label="Tag"
            source="releaseTag"
            variant="filled"
            sx={{
                width: '100%',
                margin: '0',
            }}
            placeholder={integration?.repositoryTrackingBranch}
            validate={[required]}
            onChange={(event) => setReleaseTag(event.target.value)}
        />
    </>
)

const IntegrationShow = ({ isParentOrg, namespace, orgID }) => {
    const { id } = useParams()

    const {
        data: integration,
        isLoading: integrationLoading,
    } = useGetOne('integrations', { id: id })

    const [tabValue, setTabValue] = useState(0)
    const [accountConnector, setAccountConnector] = useState()
    const unselectAll = useUnselectAll('integrationreleases')
    const [perPageCustom, setPerPageCustom] = useState(SUBGRID_RECORDS_PER_PAGE)
    const [releaseTag, setReleaseTag] = useState(
        integration?.repositoryTrackingBranch
    )
    const dataProvider = useDataProvider()
    const navigate = useNavigate()

    const { data: integrationReleases } = useGetList('integrationreleases', {
        filter: { integration_id: id },
        sort: { field: 'createdDate', order: 'DESC' },
        pagination: { page: 1, perPage: 500 },
    })
    const [sourceControlTenant, setSourceControlTenant] = useState(null)
    const [sourceControlTenantId, setSourceControlTenantId] = useState(null)

    useEffect(() => {
        if (orgID && isEmpty(sourceControlTenant)) {
            dataProvider
                .SOURCE_CONTROL_TENANT('organizations', { id: orgID })
                .then(({ data }) => {
                    setSourceControlTenant(data)
                    setSourceControlTenantId(data.id)
                })
        }
    }, [dataProvider, orgID, sourceControlTenant])
    const tenantCallback = (event) => {
        setSourceControlTenant(event.payload)
    }

    useEffect(() => {
        if (!isEmpty(sourceControlTenant)) {
            dataProvider.subscribe(
                `tenants/${sourceControlTenant.id}`,
                tenantCallback
            )
            return () =>
                dataProvider.unsubscribe(
                    `tenants/${sourceControlTenant.id}`,
                    tenantCallback
                )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceControlTenantId])

    useEffect(() => {
        setReleaseTag(integration?.repositoryTrackingBranch)
    }, [integration])

    const { data: filteredConnectors } = useConnectorsList({
        id: [trimEnvPrefix(namespace)],
    })
    useEffect(() => {
        setAccountConnector(filteredConnectors?.[0])
    }, [filteredConnectors])

    const connectors = useConnectors(integration?.connectors)

    const handleChange = (event, newValue) => {
        setTabValue(newValue)
    }

    // clears selection in integrationreleases datagrid, otherwise checked items permeate between unrelated pages
    useEffect(() => {
        unselectAll()
    }, [unselectAll])

    if (integrationLoading) {
        return <LinearProgress />
    }

    const isExternalIntegration = integration?.type === 'External'

    const sourceControlDisabled = isEmpty(sourceControlTenant)
        ? 'Could not fetch Source Control tenant.'
        : !get(sourceControlTenant, 'status.auth.connected', false)
        ? 'Source Control tenant is not connected.'
        : isEmpty(integration)
        ? 'Could not fetch integration'
        : isEmpty(integration?.repositoryUrl)
        ? 'Repository URL must be set on this integration.'
        : ''
    return (
        <BaseShow
            actions={null}
            TopToolbar={
                integration && integrationReleases ? (
                    <IntegrationTopToolBar
                        integration={integration}
                        integrationReleases={integrationReleases}
                        isParentOrg={isParentOrg}
                    />
                ) : (
                    <LinearProgress />
                )
            }
            integration={integration}
            resource={'integrations'}
        >
            <Card sx={integrationShowStyles.firstCard}>
                <Box sx={integrationShowStyles.flex1}>
                    <Box sx={integrationShowStyles.regLarge}>NAME</Box>
                    <Box sx={integrationShowStyles.boldLarge}>
                        {get(integration, 'name')}
                    </Box>
                </Box>
                <Box sx={integrationShowStyles.flex2}>
                    <Box sx={integrationShowStyles.regLarge}>CONNECTORS</Box>
                    <Box sx={integrationShowStyles.connectorsBox}>
                        {isEmpty(connectors) ? (
                            <LinearProgress />
                        ) : (
                            get(integration, 'connectors', []).map(
                                (integrationConnector) => {
                                    const connector = connectors.find(
                                        (connector) =>
                                            connector.name ===
                                            integrationConnector.name
                                    )
                                    return (
                                        <Box
                                            key={integrationConnector.name}
                                            sx={integrationShowStyles.connector}
                                        >
                                            <img
                                                src={connector.logoUrl}
                                                alt="option logo"
                                                style={
                                                    integrationShowStyles.connectorLogo
                                                }
                                            />
                                            <Box>
                                                <Box
                                                    sx={
                                                        integrationShowStyles.connectorName
                                                    }
                                                >
                                                    <Box
                                                        component="span"
                                                        sx={
                                                            integrationShowStyles.boldLarge
                                                        }
                                                    >
                                                        {Capitalize(
                                                            connector?.label ||
                                                                integrationConnector.name
                                                        )}
                                                    </Box>
                                                    {integrationConnector.isGlobal && (
                                                        <Public
                                                            sx={
                                                                integrationShowStyles.globalIcon
                                                            }
                                                        />
                                                    )}
                                                </Box>
                                                {integrationConnector.isGlobal && (
                                                    <ConnectButton
                                                        connector={connector}
                                                        integrationName={
                                                            integration.name
                                                        }
                                                    />
                                                )}
                                            </Box>
                                        </Box>
                                    )
                                }
                            )
                        )}
                    </Box>
                </Box>
            </Card>
            <SimpleShowLayout>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                        boxShadow: 'none',
                    }}
                >
                    <div>
                        <CustomLabel title="Details" />
                        <HorizontalTextField
                            source="id"
                            label={false}
                            rowName="ID"
                            record={integration}
                        />
                        <HorizontalTextField
                            source="name"
                            rowName="Name"
                            label={false}
                            record={integration}
                        />
                        <HorizontalTextField
                            source="longName"
                            rowName="Long Name"
                            label={false}
                            record={integration}
                        />
                        <HorizontalTextField
                            source="type"
                            rowName="Type"
                            label={false}
                            record={integration}
                        />
                        {!isExternalIntegration && (
                            <div>
                                <HorizontalScheduleField
                                    source="marketplaceSettings.schedule"
                                    record={integration}
                                    rowName="Default Schedule"
                                    label={false}
                                />
                                <HorizontalTextField
                                    source="repositoryUrl"
                                    record={integration}
                                    rowName="Repository URL"
                                    label={false}
                                />
                                <HorizontalTextField
                                    source="repositoryTrackingBranch"
                                    record={integration}
                                    rowName="Repository Tracking Branch"
                                    label={false}
                                />
                                <HorizontalTextField
                                    source="repositoryPath"
                                    record={integration}
                                    rowName="Repository Path"
                                    label={false}
                                />
                            </div>
                        )}
                        <HorizontalTextField
                            source="marketplaceSettings.orderIndex"
                            record={integration}
                            rowName={'Listing Order'}
                            label={false}
                        />
                    </div>
                    {!isExternalIntegration && !integration.archived && (
                        <div style={{ display: 'inline-flex' }}>
                            <Tooltip
                                PopperProps={{
                                    sx: {
                                        '& .MuiTooltip-tooltip': {
                                            fontSize: '14px',
                                            padding: '8px ',
                                            backgroundColor: 'rgba(0,0,0,0.87)',
                                        },
                                    },
                                }}
                                title={
                                    !isEmpty(sourceControlDisabled) && (
                                        <div>
                                            <p>{sourceControlDisabled}</p>
                                            {!get(
                                                sourceControlTenant,
                                                'status.auth.connected',
                                                false
                                            ) && (
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={
                                                        isParentOrg
                                                            ? '/account?tab=5'
                                                            : '/account?tab=2'
                                                    }
                                                    style={{ color: 'white' }}
                                                >
                                                    Connect here!
                                                </a>
                                            )}
                                        </div>
                                    )
                                }
                                aria-label="add"
                                placement="top"
                            >
                                <div>
                                    <SetupRepoDialog
                                        disabled={
                                            !isEmpty(sourceControlDisabled)
                                        }
                                        integration={integration}
                                        sourceControlTenant={
                                            sourceControlTenant
                                        }
                                        isParentOrg={isParentOrg}
                                    />
                                </div>
                            </Tooltip>
                            <Tooltip
                                PopperProps={{
                                    sx: {
                                        '& .MuiTooltip-tooltip': {
                                            fontSize: '14px',
                                            padding: '8px ',
                                            backgroundColor: 'rgba(0,0,0,0.87)',
                                        },
                                    },
                                }}
                                title={
                                    !isEmpty(sourceControlDisabled) && (
                                        <div>
                                            <p>{sourceControlDisabled}</p>
                                            {!get(
                                                sourceControlTenant,
                                                'status.auth.connected',
                                                false
                                            ) && (
                                                <a
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    href={
                                                        isParentOrg
                                                            ? '/account?tab=5'
                                                            : '/account?tab=2'
                                                    }
                                                    style={{ color: 'white' }}
                                                >
                                                    Connect here!
                                                </a>
                                            )}
                                        </div>
                                    )
                                }
                                aria-label="add"
                                placement="top"
                            >
                                <div>
                                    <BaseDialog
                                        initialValues={{
                                            releaseTag:
                                                integration?.repositoryTrackingBranch,
                                        }}
                                        openDialogButtonText="Build Release"
                                        dialogTitle="Manually build a new Release"
                                        dialogContentText="Set a release tag and we will build a new release for your tenants"
                                        dialogContentFields={buildDialogInputFields(
                                            integration,
                                            setReleaseTag
                                        )}
                                        disabled={
                                            !isEmpty(sourceControlDisabled)
                                        }
                                        closeDialogButtonText="Cancel"
                                        SubmitButton={(props) => (
                                            <SyncButton
                                                key="sync"
                                                label="BUILD RELEASE"
                                                record={sourceControlTenant}
                                                onClick={() =>
                                                    navigate(
                                                        isParentOrg
                                                            ? `/account?tab=5`
                                                            : `/account?tab=2`
                                                    )
                                                }
                                                syncType={'normal'}
                                                payload={{
                                                    repoType: getRepoType(
                                                        integration
                                                    ),
                                                    buildIntegration:
                                                        integration.name,
                                                    buildIntegrationID:
                                                        integration.id,
                                                }}
                                                headers={{
                                                    'X-Build-Integration-Name':
                                                        integration.name,
                                                    'X-Build-Integration-Id':
                                                        integration.id,
                                                    'X-Release-Tag': releaseTag,
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </Tooltip>
                        </div>
                    )}
                </Box>
                {!isExternalIntegration && (
                    <Fragment>
                        <CustomDivider sx={{ margin: '40px 0 0 0' }} />
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <TabsBar
                                tabs={['RUNS', 'TENANTS', 'RELEASES']}
                                tabValue={tabValue}
                                handleChange={handleChange}
                            />
                            <SelectItemsPerPage
                                perPageCustom={perPageCustom}
                                setPerPageCustom={setPerPageCustom}
                            />
                        </Box>
                    </Fragment>
                )}
                {!isExternalIntegration && tabValue === 0 && (
                    <RunDataGrid
                        filter={{ integration_id: integration?.id }}
                        perPageCustom={perPageCustom}
                    />
                )}
                {!isExternalIntegration && tabValue === 1 && (
                    <TenantList
                        filter={{ integration_id: integration?.id }}
                        namespace={namespace}
                        accountConnector={accountConnector}
                        integration={integration}
                        perPageCustom={perPageCustom}
                    />
                )}
                {!isExternalIntegration && tabValue === 2 && (
                    <ReleaseDataGrid
                        filter={{ integration_id: integration?.id }}
                        perPageCustom={perPageCustom}
                    />
                )}
            </SimpleShowLayout>
        </BaseShow>
    )
}

const SelectItemsPerPage = ({ perPageCustom, setPerPageCustom }) => {
    const handleItemPerPageChange = (event) => {
        setPerPageCustom(event.target.value)
    }
    return (
        <FormControl
            sx={{
                minWidth: '140px',
                borderRadius: '3px',
                overflow: 'hidden',
            }}
        >
            <InputLabel id="select-items-per-page-label">
                Items per page
            </InputLabel>
            <Select
                id="select-items-per-page"
                value={perPageCustom}
                onChange={handleItemPerPageChange}
            >
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={200}>200</MenuItem>
            </Select>
        </FormControl>
    )
}

const mapStateToProps = (state, props) => {
    return {
        namespace: props.namespace
            ? props.namespace
            : props.integration.namespace,
        isParentOrg: state.org.isParentOrg,
        orgID: state.org.orgId,
    }
}

export default connect(mapStateToProps)(IntegrationShow)
