import React from 'react'
import { Toolbar, AppBar } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import PandiumLogo from '../../imgs/logo.png'
import AppBarMenu from './AppBarMenu'
import { connect } from 'react-redux'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        zIndex: '9999',
    },
    appbar: {
        minHeight: '64px',
    },
    logo: {
        flexGrow: 1,
    },
    pandiumLogo: {
        backgroundImage: 'url(' + PandiumLogo + ')',
        backgroundSize: 'contain',
        backgroundPosition: 'center left',
        backgroundRepeat: 'no-repeat',
        fontFamily: 'ComfortaaRegular',
        color: 'white',
        textDecoration: 'none',
        fontSize: '30px',
        padding: '0 0 0 35px',
        margin: '0 0 0 5px',
        flexGrow: 1,
    },
    pandiumLink: {
        textDecoration: 'none',
        color: 'white',
    },
}))

const mapStateToProps = (state) => {
    return {
        topLogo: state.org.topLogo,
        isParentOrg: state.org.isParentOrg,
        parentName: state.org.parentName,
    }
}

export default connect(mapStateToProps)(
    ({ username, user_id, topLogo, isParentOrg, parentName }) => {
        const classes = useStyles()
        return (
            <div className={classes.root}>
                <AppBar color="secondary">
                    <Toolbar>
                        {!isParentOrg ? (
                            <span className={classes.logo}>
                                <img
                                    src={topLogo}
                                    height="35px"
                                    alt={parentName}
                                />
                            </span>
                        ) : (
                            <span className={classes.pandiumLogo}>
                                <a
                                    className={classes.pandiumLink}
                                    href="https://pandium.com"
                                >
                                    pandium
                                </a>
                            </span>
                        )}
                        <AppBarMenu username={username} user_id={user_id} />
                    </Toolbar>
                </AppBar>
            </div>
        )
    }
)
