import { React, useState } from 'react'
import { Confirm, TextInput, useNotify, useUpdate, Form } from 'react-admin'
import { Grid, Button, Typography } from '@mui/material'
import { get } from 'lodash-es'
import { required } from '../../../inputValidators'
import { useFormContext } from 'react-hook-form'

const styles = {
    inputField: {
        width: '100%',
        margin: '0',
    },
}

const FormActions = ({ record }) => {
    const notify = useNotify()
    const { getValues } = useFormContext()
    const [openConfirm, setOpenConfirm] = useState(false)
    const [updateUserName] = useUpdate(
        'users',
        {
            id: record.id,
            data: getValues(),
            previousData: record,
        },
        {
            onSuccess: (data) => {
                console.debug('Success: ', data)
                setOpenConfirm(false)
                notify('Name Updated Successfully!')
            },
            onFailure: (error) => {
                setOpenConfirm(false)
                notify('Error updating name', { type: 'warning' })
                console.debug('Error: ', get(error, 'message', error))
            },
        }
    )

    return (
        <Grid>
            <Button
                onClick={() => setOpenConfirm(true)}
                sx={{ margin: '4px 0 0 6px' }}
                variant="contained"
            >
                Change Name
            </Button>
            <Confirm
                isOpen={openConfirm}
                title="Change Name"
                content="Are you sure you want to change your Name?"
                onConfirm={() => updateUserName()}
                onClose={() => setOpenConfirm(false)}
                confirmColor={'#626FFC'}
            />
        </Grid>
    )
}

/**
 *
 * @param record
 * @returns {JSX.Element}
 */
export default ({ record }) => {
    return (
        <Grid item xs sx={{ padding: '0 0 0 12px' }}>
            <Grid
                item
                xs={8}
                sx={{
                    marginTop: '10px',
                    marginBottom: '50px',
                    '& .MuiTypography-h6': {
                        color: '#707279',
                        marginBottom: '14px',
                    },
                    flexDirection: 'column',
                }}
            >
                <Typography variant="h6">CHANGE NAME</Typography>
            </Grid>
            <Form defaultValues={record}>
                <Grid container spacing={1} direction="row">
                    <Grid container item xs={12} spacing={1} direction="row">
                        <Grid item xs={3}>
                            <TextInput
                                label="First Name"
                                source="firstName"
                                variant="filled"
                                sx={styles.inputField}
                                validate={required}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextInput
                                label="Last Name"
                                source="lastName"
                                variant="filled"
                                sx={styles.inputField}
                                validate={required}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'flex' }}>
                            <FormActions record={record} />
                        </Grid>
                    </Grid>
                </Grid>
            </Form>
        </Grid>
    )
}
