import React, { useState, useEffect } from 'react'
import { useFieldArray, useFormContext, FormProvider } from 'react-hook-form'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { Close } from '@mui/icons-material'
import { AddButton } from '@thefront/pandipackV2'
import Button from '@mui/material/Button'
import { get, isEmpty } from 'lodash-es'
import cronstrue from 'cronstrue'
import makeStyles from '@mui/styles/makeStyles'
import { parseExpression } from 'cron-parser'
import { TextInput } from 'ra-ui-materialui'
import { defaultSyncScheduleTimes } from './resources/integrations/Create/InternalIntegrationCreate'
import {
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
} from '@mui/material'

const useHeaderStyles = makeStyles({
    headerBase: {
        backgroundColor: '#F1F4FB',
        margin: '40px 0 24px 0',
        height: '54px',
        display: 'flex',
        flexGrow: 1,
        alignItems: 'left',
    },
    mapperTitle: {
        padding: '14px 24px 16px 24px',
        fontWeight: 'bold',
        fontFamily: 'RobotoCondensedBold',
        fontSize: '20px',
        marginBottom: '24px',
    },
})

const SyncMapperHeader = () => {
    const classes = useHeaderStyles()
    return (
        <Grid
            key={'cronMapperHeader'}
            className={classes.headerBase}
            container
            item
            xs={12}
        >
            <Grid item xs={5} className={classes.mapperTitle}>
                <span> {'SCHEDULE'} </span>
            </Grid>
            <Grid item xs={5} className={classes.mapperTitle}>
                <span> {'CRON STRING'} </span>
            </Grid>
            <Grid item xs={2} className={classes.mapperTitle}>
                <span> {''}</span>
            </Grid>
        </Grid>
    )
}

const useRowStyles = makeStyles({
    removeIcon: {
        marginLeft: '10px',
    },
    removeButtonText: {
        color: '#707279',
    },
    inputField: {
        margin: '40px 0 24px 0',
        height: '54px',
        display: 'flex',
        flexGrow: 1,
        alignItems: 'left',
    },
    label: {
        fontFamily: 'RobotoCondensedBold',
    },
})

const ScheduleRow = (props) => {
    const { onRemove, value, index, removeEnabled } = props
    const classes = useRowStyles()
    const formMethods = useFormContext()
    const [stateProperties, setStateProperties] = useState(
        !isEmpty(value) ? value : { schedule: '', cron: '' }
    )
    const [cronDescriptionText, setCronDescriptionText] = useState(
        !isEmpty(stateProperties.cron)
            ? cronstrue
                  .toString(stateProperties.cron, {
                      throwExceptionOnParseError: false,
                  })
                  .replace('AM', 'AM (UTC Time)')
                  .replace('PM', 'PM (UTC Time)')
            : '* required'
    )

    const scheduleValidation = (value, allValues) => {
        if (isEmpty(value)) return '* required'
        // if this schedule is already present in object
        const stringIndexInArray = allValues?.syncScheduleTimes.findIndex(
            (x) => x?.schedule.toLowerCase() === value.toLowerCase()
        )
        if (stringIndexInArray >= 0 && stringIndexInArray !== index) {
            return 'This option already exists'
        }
        //if no error
        return undefined
    }

    const parseExpressionWrapper = (value) => {
        parseExpression(value)
        return cronstrue
            .toString(value, {
                throwExceptionOnParseError: true,
            })
            .replace('AM', 'AM (UTC Time)')
            .replace('PM', 'PM (UTC Time)')
    }

    const cronValidation = (value, allValues) => {
        if (isEmpty(value)) return '* required'
        if (value.trim().length !== value.length)
            return 'Whitespace at end of string'
        const cronIndexInArray = allValues.syncScheduleTimes?.findIndex(
            (x) => x?.cron === value
        )
        if (cronIndexInArray >= 0 && cronIndexInArray !== index)
            return 'This option already exists'
        try {
            parseExpressionWrapper(value)
        } catch (e) {
            return e.toString()
        }
        return undefined
    }

    const setCronDescription = (cronString) => {
        if (isEmpty(cronString)) setCronDescriptionText(null)
        try {
            const str = parseExpressionWrapper(cronString)
            setCronDescriptionText(str)
        } catch (e) {
            setCronDescriptionText(null)
        }
    }

    const onScheduleChange = (event) => {
        formMethods.setValue(
            `syncScheduleTimes.${index}.schedule`,
            event.target.value
        )
        setStateProperties({
            ...stateProperties,
            schedule: event.target.value,
        })
    }

    const onCronChange = (event) => {
        formMethods.setValue(
            `syncScheduleTimes.${index}.cron`,
            event.target.value
        )
        setStateProperties({
            ...stateProperties,
            cron: event.target.value,
        })
        setCronDescription(event.target.value)
    }

    return (
        <Grid container spacing={2}>
            <React.Fragment>
                <Grid item xs={5}>
                    <div
                        {...formMethods.register(
                            `syncScheduleTimes.${index}.schedule`
                        )}
                    >
                        <TextInput
                            fullWidth
                            source={`syncScheduleTimes.${index}.schedule`}
                            name={`syncScheduleTimes.${index}.schedule`}
                            validate={[scheduleValidation]}
                            onChange={onScheduleChange}
                            margin="dense"
                            InputLabelProps={{
                                classes: { root: classes.label },
                            }}
                            label={
                                get(value, 'schedule', null)
                                    ? 'Schedule'
                                    : 'Custom Schedule'
                            }
                            defaultValue={
                                typeof get(value, 'schedule') === 'string'
                                    ? value['schedule']
                                    : ''
                            }
                            variant="filled"
                        />
                    </div>
                </Grid>
                <Grid item xs={5}>
                    <div
                        {...formMethods.register(
                            `syncScheduleTimes.${index}.cron`
                        )}
                    >
                        <TextInput
                            fullWidth
                            source={`syncScheduleTimes.${index}.cron`}
                            margin="dense"
                            InputLabelProps={{
                                classes: { root: classes.label },
                            }}
                            validate={[cronValidation]}
                            helperText={cronDescriptionText}
                            defaultValue={
                                typeof get(value, 'cron') === 'string'
                                    ? value['cron']
                                    : ''
                            }
                            onChange={onCronChange}
                            variant="filled"
                            label={'Cron String '}
                        />
                    </div>
                </Grid>
                <Grid item xs={2}>
                    <div className={classes.remove}>
                        <Button
                            classes={{
                                root: classes.removeButtonText,
                            }}
                            disabled={!removeEnabled}
                            onClick={onRemove}
                        >
                            {' '}
                            Remove <Close className={classes.removeIcon} />
                        </Button>
                    </div>
                </Grid>
            </React.Fragment>
        </Grid>
    )
}

export const CronMapper = ({ schedule, setFormErrorState }) => {
    const methods = useFormContext()
    const { fields, append, remove, replace } = useFieldArray({
        control: methods.control,
        name: 'syncScheduleTimes',
    })

    const [rowRemovalEnabled, setRowRemovalEnabled] = useState(
        fields.length > 1
    )

    useEffect(() => {
        setRowRemovalEnabled(fields.length > 1)
    }, [fields])

    const restoreDefaultRows = () => {
        replace(defaultSyncScheduleTimes)
        setConfirmOpen(false)
    }
    const [confirmOpen, setConfirmOpen] = useState(false)
    const handleConfirmOpen = () => {
        setConfirmOpen(true)
    }
    const handleConfirmClose = () => {
        setConfirmOpen(false)
    }

    return (
        <Paper elevation={0}>
            <div id={'cronMap'}>
                <SyncMapperHeader />
                <FormProvider {...methods}>
                    {fields.map((field, index) => (
                        //the below key has to be unique.
                        <div key={`div-scheduleRow-${fields.length}-${index}`}>
                            <ScheduleRow
                                onRemove={() => {
                                    remove(index)
                                }}
                                value={fields[index]}
                                index={index}
                                key={`TheRow-${fields.length}-${0}`}
                                removeEnabled={rowRemovalEnabled}
                            />
                        </div>
                    ))}
                    <AddButton
                        push={append}
                        field={{ cron: '' }}
                        label={'+ Add'}
                    />
                    <Button onClick={handleConfirmOpen}>
                        {' '}
                        Restore Default Schedule Options
                    </Button>
                    <Dialog
                        open={confirmOpen}
                        onClose={handleConfirmClose}
                        maxWidth="xs"
                    >
                        <DialogTitle>
                            {' '}
                            Restore Default Schedule Options?
                        </DialogTitle>
                        <DialogContent width={'300px'}>
                            <DialogContentText>
                                This will remove any custom schedule options.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => restoreDefaultRows()}
                                color="warning"
                            >
                                CONTINUE
                            </Button>
                            <Button onClick={handleConfirmClose}>
                                {' '}
                                Cancel{' '}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </FormProvider>
            </div>
        </Paper>
    )
}
