import { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { JsonFormsWrapper } from '../../../jsonFormComponents/JsonFormsWrapper'
import { useGetOne, Edit, useDataProvider } from 'react-admin'
import { useNavigate, useParams } from 'react-router-dom'
import { isEmpty } from 'lodash-es'
import { SelectReleasesInput } from '../../../common'
import { Box, Button, Divider, Typography } from '@mui/material'
import { getDynamicConfigSchema } from '@thefront/pandipackV2'
import { ExpandMore, ExpandLess } from '@mui/icons-material'
import { API_URI, HEADERS } from '../../../../appConfigs'
import { CustomFileEditor } from '../../../CustomFileEditor'
import { RerunDialog } from '../../../RerunDialog'
import BaseTopToolbar from '../../../react_admin/BaseTopToolbar'

const CollapsibleFormSection = ({ title, children }) => {
    const [expanded, setExpanded] = useState(false)
    return (
        <Box
            sx={{
                backgroundColor: '#F5F5F5',
                borderRadius: 3,
                mb: 2,
            }}
        >
            <Button
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: '100%',
                    textTransform: 'none',
                    borderRadius: 3,
                }}
                onClick={() => setExpanded(!expanded)}
            >
                <Typography
                    sx={{
                        color: 'black',
                        fontSize: '24px',
                        letterSpacing: 0.8,
                        ml: 1,
                    }}
                >
                    {title}
                </Typography>
                {expanded ? (
                    <ExpandLess sx={{ color: 'black' }} fontSize="large" />
                ) : (
                    <ExpandMore sx={{ color: 'black' }} fontSize="large" />
                )}
            </Button>
            {expanded && <Box sx={{ px: 2, py: 1 }}>{children}</Box>}
        </Box>
    )
}

const RerunConfiguration = () => {
    const { id: runId } = useParams()
    const navigate = useNavigate()
    const dataProvider = useDataProvider()
    const [dialogOpen, setDialogOpen] = useState(false)

    const [
        selectedIntegrationReleaseId,
        setSelectedIntegrationReleaseId,
    ] = useState()
    const [schema, setSchema] = useState()
    const [uischema, setUiSchema] = useState({})
    const [jsonFormValues, setJsonFormValues] = useState()

    const { data: run, isLoading: runLoading } = useGetOne('runs', {
        id: runId,
    })

    useEffect(() => {
        if (run) {
            setSelectedIntegrationReleaseId(run.jobSpec.integration_release.id)
            setJsonFormValues(run.jobSpec.tenant.configs ?? {})
        }
    }, [run])

    const rerunCustomConfiguration = () => {
        const rerunConfiguration = {}
        const formValues = methods.getValues()
        if (
            run.jobSpec.integration_release.id !==
            formValues.integrationRelease.id
        ) {
            rerunConfiguration.integrationRelease =
                formValues.integrationRelease.id
        }
        if (formValues.stdout !== undefined && formValues.stdout !== null) {
            let unformatted
            try {
                unformatted = JSON.stringify(JSON.parse(formValues.stdout))
            } catch {
                unformatted = formValues.stdout
            }
            rerunConfiguration.stdout = unformatted
        }
        if (
            JSON.stringify(formValues.configs) !==
            JSON.stringify(run.jobSpec.tenant.configs)
        ) {
            rerunConfiguration.configs = formValues.configs
        }
        return rerunConfiguration
    }

    // Call back to get state from child
    const onJsonFormChange = (errors, jsonFormValues) => {
        setJsonFormValues(jsonFormValues)
        methods.setValue('configs', jsonFormValues)
    }

    const methods = useForm({
        defaultValues: {
            configs: run?.jobSpec.tenant.configs,
            stdout: null,
            integrationRelease: run?.jobSpec.integration_release,
        },
        mode: 'onBlur',
    })

    // for integration release picker ---------------
    useEffect(() => {
        const getIntegrationRelease = async () => {
            const result = await dataProvider.getOne('integrationreleases', {
                id: selectedIntegrationReleaseId,
            })
            const intRel = result.data
            setSchema(
                getDynamicConfigSchema(
                    intRel.configSchema.schema,
                    run?.jobSpec.tenant.status?.dynamic_configs ?? {}
                )
            )
            setUiSchema(intRel.configSchema.uischema)
        }

        if (
            selectedIntegrationReleaseId &&
            selectedIntegrationReleaseId !== -1
        ) {
            getIntegrationRelease()
        }
    }, [selectedIntegrationReleaseId, run, dataProvider])

    // for stdout editor ----------------------------
    const [serverText, setServerText] = useState()
    const [editedText, setEditedText] = useState()
    const [isUploading, setIsUploading] = useState(false)

    useEffect(() => {
        const getStdout = async () => {
            const filepath =
                run.jobSpec.tenant.status?.last_successful_run?.stdOut
            try {
                const response = await fetch(`${API_URI}/files/${filepath}`, {
                    method: 'GET',
                    headers: HEADERS(sessionStorage.getItem('token')),
                })
                if (response.ok) {
                    const text = await response.text()
                    let formatted
                    try {
                        formatted = JSON.stringify(JSON.parse(text), null, '\t')
                    } catch (e) {
                        formatted = text
                    }
                    setServerText(formatted)
                    setEditedText(formatted)
                }
            } catch (error) {
                console.error(
                    `Error fetching STDOUT for filepath ${filepath}: ${error}`
                )
            }
        }
        if (run?.jobSpec.tenant.status?.last_successful_run?.stdOut) getStdout()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [run])

    const onEditorChange = (newValue) => {
        setEditedText(newValue)
        methods.setValue('stdout', newValue)
    }
    const onEditorReset = () => {
        setEditedText(serverText)
        methods.setValue('stdout', undefined)
    }

    return (
        !runLoading && (
            <Box>
                <BaseTopToolbar
                    pageTitle="Configure Run"
                    handleBack={() => navigate(-1)}
                    backButtonText="Back"
                />
                <Divider className="pageHead" />
                {/* TODO: this is similar to the Tenant Settings page, maybe make a shared component in the future */}
                <Edit actions={null} component="div" resource="runs">
                    <Box sx={{ maxWidth: '800px' }}>
                        <FormProvider {...methods}>
                            <CollapsibleFormSection title="Release">
                                <SelectReleasesInput
                                    source={'integrationRelease.id'}
                                    onChange={(e) => {
                                        setSelectedIntegrationReleaseId(
                                            e.target.value
                                        )
                                    }}
                                />
                            </CollapsibleFormSection>
                            <CollapsibleFormSection title="STDOUT">
                                <Typography sx={{ mb: 1 }}>
                                    Modify what will be passed into the
                                    environment variables
                                    PAN_CTX_LAST_SUCCESSFUL_RUN_STD_OUT and
                                    PAN_CTX_LAST_RUN_STD_OUT.
                                </Typography>
                                <CustomFileEditor
                                    mode="json"
                                    serverText={serverText}
                                    editedText={editedText}
                                    isUploading={isUploading}
                                    setIsUploading={setIsUploading}
                                    onEditorChange={onEditorChange}
                                    onEditorReset={onEditorReset}
                                    filenamePrefix="pandium_edited_stdout"
                                    fileType="text"
                                    resetButtonTooltip="Revert to the STDOUT that was originally passed into the run"
                                />
                            </CollapsibleFormSection>
                            <CollapsibleFormSection title="Configuration">
                                {!isEmpty(schema) &&
                                typeof schema !== 'string' ? (
                                    <JsonFormsWrapper
                                        onJsonFormChange={onJsonFormChange}
                                        schema={schema}
                                        uischema={uischema}
                                        data={jsonFormValues}
                                        dynamicConfigs={
                                            run.jobSpec.tenant.status
                                                ?.dynamic_configs ?? {}
                                        }
                                    />
                                ) : (
                                    <div>
                                        {' '}
                                        This integration release has no
                                        configurations set up or there is an
                                        issue with your recent context. <br />
                                        {schema}
                                    </div>
                                )}
                            </CollapsibleFormSection>
                            <Box
                                sx={{
                                    mt: 4,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Button onClick={() => navigate(-1)}>
                                    CANCEL
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: '165px',
                                        margin: '0 5px 0 5px',
                                    }}
                                    onClick={() => setDialogOpen(true)}
                                >
                                    RERUN
                                </Button>
                            </Box>
                        </FormProvider>
                    </Box>
                </Edit>
                <RerunDialog
                    open={dialogOpen}
                    setOpen={setDialogOpen}
                    runId={runId}
                    tenantId={run.tenantId}
                    persistContextOnly={true}
                    rerunCustomConfiguration={rerunCustomConfiguration}
                />
            </Box>
        )
    )
}

export default RerunConfiguration
