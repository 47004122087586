import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Box,
} from '@mui/material'
import { useForm, FormProvider } from 'react-hook-form'

const baseDialogStyles = {
    dialogRoot: {
        padding: '10px',
        overflowY: 'visible',
    },
    dialogContainer: {
        display: 'inline',
    },
    dialogContent: {
        width: '300px',
        padding: '8px 16px',
    },
    dialogContentText: {
        fontFamily: 'RobotoCondensedRegular',
        fontSize: '16px',
    },
    dialogTitle: {
        padding: '16px 16px 0 16px',
    },
}

/**
 * @param SubmitButton - a react component with submit functionality
 * @param launchButtonClasses
 * @param initialValues
 * @param openDialogButtonText
 * @param dialogTitle
 * @param dialogContentText
 * @param dialogContentFields
 * @param closeDialogButtonText
 * @returns {JSX.Element}
 */
export default ({
    SubmitButton,
    dialogLauncherVariant,
    initialValues,
    openDialogButtonText,
    dialogTitle,
    dialogContentText,
    dialogContentFields,
    closeDialogButtonText,
    disabled,
}) => {
    const [open, setOpen] = useState(false)

    const methods = useForm({
        defaultValues: initialValues,
        mode: 'onBlur',
    })

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <Box sx={baseDialogStyles.dialogContainer}>
            <Button
                variant={dialogLauncherVariant}
                onClick={handleClickOpen}
                disabled={disabled}
            >
                {openDialogButtonText}
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                sx={{ paper: baseDialogStyles.dialogRoot }}
            >
                <FormProvider {...methods}>
                    <>
                        <DialogTitle sx={baseDialogStyles.dialogTitle}>
                            {dialogTitle}
                        </DialogTitle>
                        <DialogContent sx={baseDialogStyles.dialogContent}>
                            <DialogContentText
                                sx={baseDialogStyles.dialogContentText}
                            >
                                {dialogContentText}
                            </DialogContentText>
                            {dialogContentFields}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>
                                {closeDialogButtonText}
                            </Button>
                            <SubmitButton
                                handleSubmit={() => handleClose()}
                                handleClose={() => handleClose()}
                            />
                        </DialogActions>
                    </>
                </FormProvider>
            </Dialog>
        </Box>
    )
}
