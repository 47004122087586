import React from 'react'
import BaseList from '../../List/BaseList'
import BaseDatagrid from '../../../react_admin/BaseDatagrid'
import { TextField as RATextField, FunctionField } from 'react-admin'
import ListTopToolbar from '../../List/ListTopToolbar'
import PartnerFilterToolbar from './PartnerFilterToolbar'
import LinkField from '../../../LinkField'
import RACreateButton from '../../../react_admin/RACreateButton'
import { PARTNERS_PER_PAGE } from '../../../../appConfigs'
import { Box } from '@mui/material'

const PartnerDatagrid = (props) => {
    return (
        <BaseDatagrid rowClick={'show'} bulkActionButtons={false}>
            <FunctionField
                source="name"
                render={(record) => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            '& img': {
                                height: '40px',
                                width: '40px',
                                paddingRight: '2px',
                            },
                        }}
                    >
                        {record.settings?.secondaryLogo && (
                            <img src={record.settings.secondaryLogo} alt="" />
                        )}{' '}
                        {record.name}{' '}
                    </Box>
                )}
                label="Organization"
            />
            <RATextField
                source="primaryUser.email"
                label="Contact"
                sortable={false}
            />
            <RATextField
                source="tenantsCount"
                label="Tenants"
                //TODO: make api sort by tenants_count and integrations_count
                sortable={false}
            />
            <RATextField
                source="integrationsCount"
                label="Integrations"
                sortable={false}
            />
            <LinkField
                className="detailLink"
                basePath="partners"
                redirect="show"
                sortable={false}
                variant="outlined"
            />
        </BaseDatagrid>
    )
}

const PartnerTopToolbar = () => {
    return (
        <ListTopToolbar pageTitle="Partners">
            <Box
                sx={{
                    width: '300px',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <RACreateButton
                    resource="partners"
                    label="Invite"
                    queryString={'?invitePartner=true'}
                    helpText="For partner approval, they must fill out a form upon initial sign-in."
                    variant="text"
                />
                <RACreateButton
                    resource="partners"
                    variant="action"
                    helpText="Partners will be automatically created, approved, and ready to submit integrations."
                />
            </Box>
        </ListTopToolbar>
    )
}

export default ({ namespace, ...props }) => {
    return (
        <>
            <BaseList
                {...props}
                TopToolbar={PartnerTopToolbar}
                filters={<PartnerFilterToolbar />}
                filter={{ namespace: namespace, org_type: 'PARTNER' }}
                actions={null}
                bulkActionButtons={null}
                perPage={PARTNERS_PER_PAGE}
            >
                <PartnerDatagrid />
            </BaseList>
        </>
    )
}
