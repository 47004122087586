import React, { useEffect, useState } from 'react'
import { LogField } from '../../LogField'
import { SimpleShowLayout, useDataProvider, useGetOne } from 'react-admin'
import { get, isEmpty } from 'lodash-es'
import {
    Card,
    Button,
    LinearProgress,
    Box,
    Typography,
    Tooltip,
} from '@mui/material'
import { HorizontalTextField, RunPhaseField, LinkWithArrow } from '../../common'
import { format } from 'date-fns'
import BaseShow from '../Show/BaseShow'
import { useParams, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { StdoutField } from '../../StdoutField'
import BaseTopToolbar from '../../react_admin/BaseTopToolbar'
import { RerunDialog } from '../../RerunDialog'

const RunTopToolbar = ({
    tenantConnected,
    tenantSyncing,
    runPhase,
    runId,
    tenantId,
    runTrigger,
    isSourceControlRun,
}) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    return (
        <BaseTopToolbar
            pageTitle={isSourceControlRun ? 'Build Detail' : 'Run Detail'}
            handleBack={() => navigate(-1)}
            backButtonText="Back"
        >
            {!isSourceControlRun && (
                <Tooltip
                    PopperProps={{
                        sx: {
                            '& .MuiTooltip-tooltip': {
                                fontSize: '14px',
                                padding: '8px ',
                                backgroundColor: 'rgba(0,0,0,0.87)',
                            },
                        },
                    }}
                    title={
                        runTrigger === 'rerun'
                            ? 'Go to the original run to start a rerun.'
                            : tenantSyncing
                            ? 'There is a sync in progress. Please wait to sync again.'
                            : !tenantConnected
                            ? 'Tenant is not connected. Please connect tenant to start a rerun.'
                            : undefined
                    }
                >
                    <span>
                        <Button
                            disabled={
                                tenantSyncing ||
                                !tenantConnected ||
                                !(
                                    runPhase?.includes('Failed') ||
                                    runPhase === 'Succeeded'
                                ) ||
                                runTrigger === 'rerun'
                            }
                            onClick={() => setOpen(true)}
                            variant="action"
                        >
                            RERUN
                        </Button>
                    </span>
                </Tooltip>
            )}
            <RerunDialog
                open={open}
                setOpen={setOpen}
                runId={runId}
                tenantId={tenantId}
            />
        </BaseTopToolbar>
    )
}

const generalCardStyles = {
    valueText: {
        fontFamily: 'RobotoCondensedBold',
        fontSize: '20px',
        paddingBottom: '5px',
    },
    fieldText: {
        color: '#6d6d6d',
        fontSize: '20px',
        marginBottom: '10px',
    },
    flex1: {
        flex: '1',
        padding: '3px',
    },
    flex0: {
        flex: '0',
        flexBasis: '12%',
        padding: '3px',
    },
    firstCard: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        boxShadow: 'none',
        flex: '1',
        borderRadius: '0',
        margin: '0px 0 30px 0',
        padding: '10px 50px 10px 50px',
    },
}

const GeneralCard = ({
    record,
    userId,
    tenantId,
    isSourceControlRun,
    isSuperUser,
}) => {
    // very specific line of code in order for users to get a link to the integration being built by source control
    const integrationBuilt =
        isSourceControlRun &&
        !['init', 'webhook'].includes(record.mode) &&
        record.triggers?.[0]?.payload?.headers['x-build-integration-id']

    return (
        <Card sx={generalCardStyles.firstCard}>
            <Box sx={generalCardStyles.flex1}>
                <Box sx={generalCardStyles.fieldText}>ID</Box>
                <Box sx={generalCardStyles.valueText}>{record.id}</Box>
            </Box>
            {(isSuperUser ||
                record?.jobSpec?.integration?.type !== 'Pandium') && (
                <Box sx={generalCardStyles.flex1}>
                    <Box sx={generalCardStyles.fieldText}>TENANT</Box>
                    <LinkWithArrow
                        to={'/tenants/' + tenantId + '/show'}
                        sx={generalCardStyles.valueText}
                        text={userId ?? tenantId}
                    />
                </Box>
            )}
            {(isSuperUser ||
                record.jobSpec?.integration?.type !== 'Pandium') && (
                <Box sx={generalCardStyles.flex1}>
                    <Box sx={generalCardStyles.fieldText}>INTEGRATION</Box>
                    <LinkWithArrow
                        to={`/integrations/${record?.integrationId}/show`}
                        sx={generalCardStyles.valueText}
                        text={record.jobSpec?.integration?.name}
                    />
                </Box>
            )}
            {integrationBuilt && (
                <Box sx={generalCardStyles.flex1}>
                    <Box sx={generalCardStyles.fieldText}>
                        INTEGRATION BUILT
                    </Box>
                    <LinkWithArrow
                        to={`/integrations/${integrationBuilt}/show`}
                        sx={generalCardStyles.valueText}
                        text={
                            record.triggers[0].payload.headers[
                                'x-build-integration-name'
                            ]
                        }
                    />
                </Box>
            )}
            {!isSourceControlRun && (
                <Box sx={generalCardStyles.flex0}>
                    <Box sx={generalCardStyles.fieldText}>MODE</Box>
                    <Box sx={generalCardStyles.valueText}>{record.mode}</Box>
                </Box>
            )}
            <Box sx={generalCardStyles.flex0}>
                <Box sx={generalCardStyles.fieldText}>TRIGGER</Box>
                {record.trigger === 'rerun' &&
                record.triggers?.[0]?.payload?.rerun?.run_id ? (
                    <LinkWithArrow
                        to={`/runs/${record.triggers[0].payload.rerun.run_id}/show`}
                        sx={generalCardStyles.valueText}
                        text={record.trigger}
                    />
                ) : (
                    <Box sx={generalCardStyles.valueText}>{record.trigger}</Box>
                )}
            </Box>
            <Box sx={generalCardStyles.flex0}>
                <Box sx={generalCardStyles.fieldText}>STATUS</Box>
                <Box sx={generalCardStyles.valueText}>
                    <RunPhaseField record={record} source="status.phase" />{' '}
                </Box>
            </Box>
        </Card>
    )
}

const configsStyles = {
    configurationCard: {
        borderBottom: '1px solid #D8D8D8',
        borderTop: '1px solid #D8D8D8',
        boxShadow: 'none',
        flex: '1',
        borderRadius: '0',
        padding: '36.5px 50px 25.5px 50px',
        display: 'flex',
        margin: '0px 0 30px 0',
        '& > div': {
            width: '100%',
        },
    },
    fieldText: {
        color: '#6d6d6d',
        fontSize: '20px',
        marginBottom: '10px',
    },
}

const ConfigsAndDetails = ({ configs, tenantStatus, runStatus, details }) => {
    return (
        <Card sx={configsStyles.configurationCard}>
            <div>
                <Typography sx={configsStyles.fieldText}>
                    CONFIGURATION
                </Typography>
                {Object.keys(configs).map((key) => (
                    <HorizontalTextField
                        key={key}
                        record={configs}
                        source={key}
                        label={false}
                    />
                ))}
            </div>
            <div>
                <Typography sx={configsStyles.fieldText}>DETAILS</Typography>
                {Object.keys(details).map((key) => (
                    <HorizontalTextField
                        key={key}
                        record={details}
                        source={key}
                        label={false}
                    />
                ))}
                <Box sx={{ height: '6px' }} />
                <StdoutField
                    filepath={tenantStatus?.last_run?.stdOut}
                    runType={'last_run'}
                    label={'Last Run STDOUT'}
                />
                <StdoutField
                    filepath={tenantStatus?.last_successful_run?.stdOut}
                    runType={'last_successful_run'}
                    label={'Last Successful Run STDOUT'}
                />
                <StdoutField
                    filepath={runStatus.stdOut}
                    runType={'current_run'}
                    label={'Current Run STDOUT'}
                />
            </div>
        </Card>
    )
}

const RunShow = ({ namespace, isSuperUser }) => {
    const dataProvider = useDataProvider()
    const [tenant, setTenant] = useState()
    const { id } = useParams()
    const { data: run } = useGetOne('runs', { id: id })

    const isSourceControlRun =
        run?.jobSpec?.integration?.type === 'Pandium' &&
        run?.jobSpec?.integration?.name.includes('source-control')

    // the rerun button is disbled if the tenant is currently syncing
    // if this run was a rerun then the rerun button will be disabled regardless, so no need to subscribe
    const tenantCallback = (event) => {
        setTenant(event.payload)
    }
    useEffect(() => {
        if (run && run.trigger !== 'rerun' && !isSourceControlRun) {
            dataProvider.subscribe(`tenants/${run?.tenantId}`, tenantCallback)
            return () =>
                dataProvider.unsubscribe(
                    `tenants/${run?.tenantId}`,
                    tenantCallback
                )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [run])

    if (!run) {
        return <LinearProgress />
    }

    const tenantFromJobSpec = run.jobSpec?.tenant
    const details = {
        Start: run.startedDate
            ? format(new Date(run.startedDate), 'M/dd/yyyy, h:mm:ss aa')
            : 'n/a',
        Finish: run.completedDate
            ? format(new Date(run.completedDate), 'M/dd/yyyy, h:mm:ss aa')
            : 'in progress',
        'Integration Release': run.jobSpec?.integration_release?.tag,
    }

    const tenantConfigs = tenantFromJobSpec.configs
    // for source control runs, override configs from the trigger payload headers
    if (isSourceControlRun) {
        tenantConfigs['force_build_id'] =
            run.triggers?.[0]?.payload?.headers['x-build-integration-id'] ??
            tenantConfigs['force_build_id']
        tenantConfigs['force_build'] =
            run.triggers?.[0]?.payload?.headers['x-build-integration-name'] ??
            tenantConfigs['force_build']
        tenantConfigs['tag_name'] =
            run.triggers?.[0]?.payload?.headers['x-release-tag'] ??
            tenantConfigs['tag_name']
    }

    return (
        <BaseShow
            actions={null}
            resource="runs"
            TopToolbar={
                <RunTopToolbar
                    tenantConnected={tenant?.status?.auth?.connected}
                    tenantSyncing={!isEmpty(tenant?.status?.currentRun)}
                    runPhase={run.status?.phase}
                    runId={id}
                    tenantId={run.jobSpec?.tenant.id}
                    runTrigger={run.trigger}
                    isSourceControlRun={isSourceControlRun}
                />
            }
        >
            <SimpleShowLayout>
                <GeneralCard
                    isSuperUser={isSuperUser}
                    isSourceControlRun={isSourceControlRun}
                    record={run}
                    userId={get(tenantFromJobSpec, 'name')}
                    tenantId={get(tenantFromJobSpec, 'id')}
                />
                {tenantFromJobSpec && (
                    <ConfigsAndDetails
                        configs={tenantConfigs}
                        tenantStatus={tenantFromJobSpec.status}
                        runStatus={run.status}
                        details={details}
                    />
                )}
                {
                    <LogField
                        record={run}
                        source="id"
                        namespace={namespace}
                        label={false}
                    />
                }
            </SimpleShowLayout>
        </BaseShow>
    )
}

const mapStateToProps = (state) => ({
    isSuperUser: state.org.isSuperUser,
})

export default connect(mapStateToProps)(RunShow)
