import React, { useState } from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useDataProvider } from 'react-admin'
import ConnectorSecretsDialog from './ConnectorSecretsDialog'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontFamily: 'Roboto',
        textAlign: 'center',
        padding: '12px 10px',
        fontSize: '12px',
        backgroundColor: 'rgba(0, 0, 0, 0.87)',
    },
}))

const ConnectorSecretsButton = (props) => {
    const dataProvider = useDataProvider()
    const { connector, tenant } = props
    const connectorName = connector.name
    const [openInfoDialog, setOpenInfoDialog] = useState(false)
    const [dialogLoading, setDialogLoading] = useState(false)

    const [connectorSecrets, setConnectorSecret] = useState({})

    const handleOpenSecretsDialog = () => {
        setDialogLoading(true)
        dataProvider
            .GET_AUTHOR(
                `author/connector_secrets/${tenant.id}/${connectorName}`
            )
            .then((res) => {
                if (res.data) {
                    setConnectorSecret(res.data)
                    setDialogLoading(false)
                }
            })
            .catch((error) => {
                setDialogLoading(false)
            })
        setOpenInfoDialog(true)
    }

    const handleCloseSecretsDialog = () => {
        setOpenInfoDialog(false)
    }

    return (
        <div
            style={{
                background:
                    'linear-gradient(47.86108581789776deg, rgba(157, 116, 249, 1) 0%, rgba(121, 204, 255, 1) 100%)',
                borderRadius: '4px',
                maxWidth: 'max-content',
            }}
        >
            <StyledTooltip title="Connector Secrets" placement="bottom">
                <span>
                    <Button
                        children="Secrets"
                        onClick={handleOpenSecretsDialog}
                        sx={{
                            color: 'white',
                            letterSpacing: '1.5px',
                            padding: '3px',
                        }}
                    />
                </span>
            </StyledTooltip>

            <ConnectorSecretsDialog
                close={handleCloseSecretsDialog}
                open={openInfoDialog}
                dialogLoading={dialogLoading}
                connector={connector}
                connectorSecrets={connectorSecrets}
            />
        </div>
    )
}

export default ConnectorSecretsButton
