import React from 'react'
import { Tab, Tabs } from '@mui/material'

/**
 * A Pandium-styled bar of tabs
 * @param tabs an array of tab names
 * @param handleChange a function that takes in (event, value) and returns a new tab value
 * @param tabValue the current value of the tab, must be an int between 0 and tabs.length - 1
 **/
export default ({ tabs, handleChange, tabValue }) => {
    return (
        <Tabs
            textColor="secondary"
            sx={(theme) => ({
                margin: '15px 0',
                '& .MuiTabs-indicator': {
                    backgroundColor: theme.PandiumColors.blue,
                    height: '3px',
                },
            })}
            value={tabValue}
            onChange={handleChange}
        >
            {tabs.map((tab) => (
                <Tab
                    key={tab}
                    sx={(theme) => ({
                        borderBottom: `2px solid ${theme.PandiumColors.grey}`,
                        padding: '12px 30px',
                        opacity: '1',
                        '&:disabled': {
                            opacity: '1',
                        },
                    })}
                    label={tab}
                    disabled={!handleChange}
                />
            ))}
        </Tabs>
    )
}
