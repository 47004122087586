import React from 'react'
import { Box, Button, Grid, Typography } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'

/**
 * A pandium themed top tool bar with a built in back button that actually brings you
 * back from whence you came.
 * @param children - the tool bar buttons
 * @param pageTitle
 * @param name - the small grey name in the toolbar
 * @param handleBack
 * @param backButtonText
 * @returns {*}
 */
export default ({ children, pageTitle, name, handleBack, backButtonText }) => {
    return (
        <Grid container spacing={3}>
            {handleBack && (
                <Grid item xs={12}>
                    <Button
                        onClick={handleBack}
                        sx={(theme) => ({ color: theme.PandiumColors.blue })}
                    >
                        <KeyboardArrowLeft />{' '}
                        {backButtonText ? backButtonText : 'BACK'}
                    </Button>
                </Grid>
            )}
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                item
                xs={4}
                wrap="nowrap"
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontFamily: 'RobotoCondensedBold',
                        margin: '0px 0 16px 0px',
                    }}
                >
                    {' '}
                    {pageTitle}{' '}
                </Typography>
                {name && ['My Account', 'Partner Detail'].includes(pageTitle) && (
                    <Box
                        sx={(theme) => ({
                            margin: '0px 0 0 27px',
                            color: '#6d6d6d',
                            [theme.breakpoints.down('sm')]: {
                                display: 'none',
                            },
                        })}
                    >
                        NAME: {name}
                    </Box>
                )}
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={8}
                spacing={1}
                wrap="nowrap"
            >
                {children &&
                    React.Children.map(children, (child, idx) => (
                        <Grid
                            item
                            key={idx}
                            sx={(theme) => ({
                                '& > .MuiButton-text': {
                                    color: theme.PandiumColors.blue,
                                },
                            })}
                        >
                            {child}
                        </Grid>
                    ))}
            </Grid>
        </Grid>
    )
}
