import React from 'react'
import { useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import { Edit, useRecordContext, useSaveContext, useNotify } from 'react-admin'
import { useForm, FormProvider } from 'react-hook-form'
import PandiForm from './PandiForm'
import {
    themeConfigSchema,
    marketplaceContentSchema,
    galleryCustomizationSchema,
} from './ThemeConfigSchemas'
import { updateOrgAction } from '../../../customActions'

const EditForm = ({ orgName, tabName }) => {
    const record = useRecordContext()
    const notify = useNotify()
    const dispatch = useDispatch()

    const methods = useForm({
        defaultValues: record,
    })

    const { save } = useSaveContext()

    let schema
    if (tabName === 'Theme') {
        schema = themeConfigSchema(orgName)
    } else if (tabName === 'Content') {
        schema = marketplaceContentSchema()
    } else if (tabName === 'Gallery') {
        schema = galleryCustomizationSchema()
    }

    return (
        <FormProvider {...methods}>
            <PandiForm schema={schema} values={methods.getValues()} />
            <Button
                size="large"
                onClick={() => {
                    save(methods.getValues(), {
                        onSuccess: (data) => {
                            methods.reset(data)
                            notify('Updated Configs')
                            dispatch(updateOrgAction({ theme: data }))
                        },
                    })
                }}
                variant="contained"
                sx={{
                    marginTop: '10px',
                    width: '165px',
                }}
                disabled={!methods.formState.isDirty}
            >
                Save
            </Button>
        </FormProvider>
    )
}

export default (props) => {
    return props.id ? (
        <Edit
            id={props.id}
            resource={'themeconfigs'}
            basePath={'/themeconfigs'}
            actions={null}
            redirect={false}
            component="div"
            mutationMode="pessimistic"
        >
            <EditForm tabName={props.tabName} />
        </Edit>
    ) : (
        <div></div>
    )
}
