import React from 'react'
import { useRedirect, TextInput } from 'react-admin'
import BaseTopToolbar from '../../../react_admin/BaseTopToolbar'
import { Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Grid } from '@mui/material'
import BaseDialog from '../../../BaseDialog'
import { UserCreateButton } from '../../user/UserListTab'
import { required, validateEmail } from '../../../inputValidators'

const usePartnerTopToolbarStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 12px',
    },
    inputField: {
        width: '100%',
        margin: '0',
    },
    fullNameWrapper: {
        display: 'flex',
    },
})

export default ({ partnerId, groupId }) => {
    const redirect = useRedirect()
    const classes = usePartnerTopToolbarStyles()

    const handleBack = () => {
        redirect('/partners')
    }

    const handleEditPartner = () => {
        redirect(`/partners/${partnerId}/edit`)
    }

    const createDialogInputFields = (
        <>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextInput
                        label="First Name"
                        source="firstName"
                        variant="filled"
                        className={classes.inputField}
                        validate={required}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextInput
                        label="Last Name"
                        source="lastName"
                        variant="filled"
                        className={classes.inputField}
                        validate={required}
                    />
                </Grid>
            </Grid>
            <TextInput
                label="Email"
                source="email"
                variant="filled"
                className={classes.inputField}
                validate={[required, validateEmail]}
            />

            {/* TODO: support role selector for partner users
            <SelectInput
                label="Role"
                source="role"
                variant="outlined"
                helperText="Restricts a user to specified sections of AdminDash"
                validate={required}
                disabled
                choices={[{ id: 'admin', name: 'Admin' }]}
            /> */}
        </>
    )

    return (
        <BaseTopToolbar
            pageTitle="Partner Detail"
            handleBack={handleBack}
            backButtonText="ALL PARTNERS"
        >
            <Button onClick={handleEditPartner}> EDIT PARTNER </Button>
            <BaseDialog
                initialValues={{
                    role: 'partner',
                    enabled: true,
                    groups: groupId,
                }}
                disabled={!groupId}
                openDialogButtonText="Add User"
                dialogTitle="Add New User"
                dialogContentText="We'll email login info and ask for a password change."
                dialogContentFields={createDialogInputFields}
                dialogLauncherVariant="contained"
                closeDialogButtonText="Cancel"
                SubmitButton={(props) => (
                    <UserCreateButton {...props} groups={groupId} />
                )}
            />
        </BaseTopToolbar>
    )
}
