import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Box,
} from '@mui/material'
import { useRedirect, useNotify, useDelete } from 'react-admin'

const RESOURCENAMES = {
    integrations: 'Integration',
    tenants: 'Tenant',
    integrationreleases: 'Integration Release',
    jobs: 'Job',
    connectors: 'Connector',
    partners: 'Partner',
    organizations: 'Partner',
    users: 'User',
}

/**
 * Deletes single resource
 * @param record
 * @param resource
 * @param children
 * @returns {*}
 * @constructor
 */
const DeleteButton = ({
    record,
    resource,
    children,
    nestedResource,
    path,
    disabled,
}) => {
    const redirect = useRedirect()
    const notify = useNotify()
    const [open, setOpen] = useState(false)
    const resourceType = nestedResource ? nestedResource : resource

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const [deleteResource, { isLoading }] = useDelete(
        resourceType,
        {
            id: record.id,
            previousData: record,
        },
        {
            onSuccess: ({ data }) => {
                notify(`${RESOURCENAMES[resourceType]} Deleted Successfully`)
                handleClose()
                redirect(path)
            },
            onFailure: (error) => {
                notify(
                    `Error deleting ${RESOURCENAMES[resourceType]}: ${error.message}`,
                    'warning'
                )
                handleClose()
            },
        }
    )

    return (
        <Box display="flex">
            <Button
                color="warning"
                onClick={handleClickOpen}
                disabled={isLoading || disabled}
            >
                {children}
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="xs">
                <DialogTitle>
                    {' '}
                    Delete {RESOURCENAMES[resourceType]}?{' '}
                </DialogTitle>
                <DialogContent sx={{ width: '300px' }}>
                    <DialogContentText>
                        This action is permanent!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => deleteResource()} color="warning">
                        Delete
                    </Button>
                    <Button onClick={handleClose}> Cancel </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default DeleteButton
