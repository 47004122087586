import React, { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { isConnectorConnected } from '@thefront/pandipackV2'
import { DisconnectDialog } from '../common'
import { get, isEmpty } from 'lodash-es'
import { useDataProvider, useNotify } from 'react-admin'
import ConnectDialog from './ConnectDialog'
// TODO this is custom for kombo..
import { showKomboConnect } from '@kombo-api/connect'

const connectDisconnectButtonStyle = {
    padding: '3px',
    letterSpacing: '1.5px',
    minWidth: 0,
    padding: '3px',
}

const ConnectButton = (props) => {
    const { connector, tenant } = props
    const isGlobal = !!props.integrationName // integrationName is only provided when this is used from the IntegrationShow page for a global connector
    const integrationName = props.integrationName || tenant.integration.name
    const dataProvider = useDataProvider()
    const notify = useNotify()
    const [openConnectDialog, setOpenConnectDialog] = useState(false)
    const [openDisconnectDialog, setOpenDisconnectDialog] = useState(false)
    const [oAuth2Link, setOauth2Link] = useState('')
    const [modalError, setModalError] = useState('')
    const [modalLoading, setModalLoading] = useState(false)

    // TODO this is custom for kombo..
    const [komboToken, setKomboToken] = useState('')

    useEffect(() => {
        if (!isEmpty(komboToken)) {
            connectBasic('kombo', { token: komboToken })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [komboToken])

    const connectBasicFailure = (error) => {
        // TODO remove toString once we normalize the returned error from author
        notify(error.toString(), { type: 'error' })
        setModalError(error.toString())
        setModalLoading(false)
        setOpenConnectDialog(true)
    }

    const connectBasicLoading = () => {
        setModalError('')
        setModalLoading(true)
    }

    const connectBasicSuccess = () => {
        setModalError('')
        setModalLoading(false)
        console.debug('CONNECT BASIC SUCCESS:', connector.name)
        notify('Save Success!')
        setOpenConnectDialog(false)
    }

    // Redirect to oauth2 link if applicable, else render the connector form
    const handleOpenConnectDialog = () => {
        if (
            connector.type !== 'basic' &&
            get(connector, 'metadata.multi_step') !== true &&
            ['web', 'hybrid', 'custom'].includes(
                get(connector, 'metadata.grant_flow')
            )
        ) {
            connectRequest(connector.name)
        } else {
            setOpenConnectDialog(true)
        }
        if (get(connector, 'metadata.grant_flow') === 'custom') {
            setOpenConnectDialog(true)
        }
    }

    const handleCloseConnectDialog = () => {
        setModalError('')
        setOpenConnectDialog(false)
    }

    const handleOpenDisconnectDialog = () => {
        setOpenDisconnectDialog(true)
    }

    const handleCloseDisconnectDialog = () => {
        setOpenDisconnectDialog(false)
    }

    const disconnectRequest = () => {
        const resource = isGlobal
            ? 'author/disconnect_global_connector'
            : 'author/disconnect_tenant'
        dataProvider
            .AUTHOR(resource, {
                data: {
                    connector_name: connector.name,
                    integration_name: integrationName,
                    tenant_id: tenant?.id,
                },
            })
            .then(() => {
                console.debug('Disconnect SUCCESS: ', connector.name)
                notify('Disconnect Success!')
            })
    }

    const connectRequest = (connectorName, form_data = undefined) => {
        setModalError('')
        const resource = isGlobal
            ? 'author/connect_global_connector'
            : 'author/connect_tenant'
        dataProvider
            .AUTHOR(resource, {
                data: {
                    connector_name: connectorName,
                    integration_name: integrationName,
                    tenant_id: tenant?.id,
                    form_data,
                },
            })
            .then(async (res) => {
                // TODO this is custom for kombo..
                if (connectorName === 'kombo') {
                    setKomboToken(await showKomboConnect(res.data.link))
                } else if (res.data.link) {
                    setOauth2Link(res.data.link)
                    setOpenConnectDialog(true)
                } else if (res.data.installUrl) {
                    //look for install URL / custom linnworks login flow
                    setOauth2Link(res.data.installUrl)
                    setOpenConnectDialog(true)
                }
            })
            .catch((error) => {
                const resolutionMessage = error.message.detail?.includes(
                    'No secrets provisioned '
                )
                    ? 'Provision this connector before attempting to connect'
                    : 'Please check credentials.'
                const errorMessage = `Error creating OAuth2 Link. ${resolutionMessage}`
                notify(errorMessage)
                setModalError(errorMessage)
                console.debug(error)
            })
    }

    const connectBasic = (connectorName, values) => {
        if (isGlobal) {
            connectRequest(connectorName, values)
            connectBasicSuccess()
            return
        }
        connectBasicLoading()
        dataProvider
            .AUTHOR('author/callback/basic', {
                data: {
                    connector_name: connectorName,
                    integration_name: integrationName,
                    tenant_id: tenant.id,
                    secrets: values,
                },
            })
            .then((res) => {
                connectBasicSuccess()
            })
            .catch((err) => {
                console.debug(err)
                connectBasicFailure(err.message.detail || err.message)
            })
    }

    const disconnect = () => {
        disconnectRequest()
        setOpenConnectDialog(false)
    }

    return (
        <>
            {isGlobal ? (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                    }}
                >
                    <Button
                        children="Connect"
                        onClick={handleOpenConnectDialog}
                        sx={connectDisconnectButtonStyle}
                    />
                    <Button
                        children="Disconnect"
                        onClick={handleOpenDisconnectDialog}
                        sx={connectDisconnectButtonStyle}
                    />
                </Box>
            ) : !isConnectorConnected(tenant, connector.name) ? (
                <span>
                    <Button
                        children="Reconnect"
                        onClick={handleOpenConnectDialog}
                        sx={connectDisconnectButtonStyle}
                    />
                </span>
            ) : (
                <span>
                    <Button
                        children="Disconnect"
                        onClick={handleOpenDisconnectDialog}
                        sx={connectDisconnectButtonStyle}
                    />
                </span>
            )}
            {/*need to wrap this in a form so react-admin input components get a 'control' prop...*/}
            <form style={{ marginBottom: '0px' }}>
                <>
                    <ConnectDialog
                        close={handleCloseConnectDialog}
                        open={openConnectDialog}
                        connectorName={connector.name}
                        connector={connector}
                        tenant={tenant}
                        oAuth2Link={oAuth2Link}
                        modalError={modalError}
                        modalLoading={modalLoading}
                        connectRequest={connectRequest}
                        connectBasic={connectBasic}
                    />
                    <DisconnectDialog
                        handleClose={handleCloseDisconnectDialog}
                        open={openDisconnectDialog}
                        connector={connector}
                        disconnect={disconnect}
                    />
                </>
            </form>
        </>
    )
}

export default ConnectButton
