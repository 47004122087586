import { Button } from '@mui/material'
import React from 'react'
import {
    useDataProvider,
    useListContext,
    useNotify,
    useUnselectAll,
} from 'react-admin'
import JSZip from 'jszip'
import { CloudDownload } from '@mui/icons-material'

export default () => {
    const { selectedIds } = useListContext()
    const unselectAll = useUnselectAll('runs')
    const notify = useNotify()
    const dataProvider = useDataProvider()

    const downloadLogs = async (runIds) => {
        const count = runIds.length
        notify(`Downloading logs for ${count} ${count > 1 ? 'runs' : 'run'}`)
        unselectAll()
        const zip = new JSZip()
        for (let id of runIds) {
            try {
                const res = await dataProvider.GET_ONE_LOG('runs', { id })
                zip.file(`${id}.txt`, res.data)
            } catch (err) {
                console.error(`Error fetching logs for run ID ${id}: ${err}`)
            }
        }
        zip.generateAsync({ type: 'blob' }).then((content) => {
            const element = document.createElement('a')
            element.href = URL.createObjectURL(content)
            element.download = 'pandium_run_logs.zip'
            document.body.appendChild(element) // Required for this to work in FireFox
            element.click()
        })
    }

    return (
        <Button onClick={() => downloadLogs(selectedIds)}>
            <CloudDownload sx={{ margin: '0 10px 0 0' }} />
            DOWNLOAD LOGS
        </Button>
    )
}
