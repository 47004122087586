import React, { useState } from 'react'
import { TextInput } from 'ra-ui-materialui'
import { Typography, Divider, Button, IconButton, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useRecordContext, useSaveContext, useRedirect } from 'react-admin'
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown'
import { useForm, FormProvider } from 'react-hook-form'

export const ReadFeedback = ({ feedback, parentName }) => {
    return (
        <Box
            sx={{
                width: '95%',
                backgroundColor: 'RGBA(237, 250, 120, 0.33)',
                padding: '12px 24px',
                border: 'solid 1px #EDFA78',
                marginBottom: '40px',
                '& > h6': {
                    fontSize: '20px',
                    fontWeight: '700',
                    lineHeight: '3',
                },
                '& > pre': {
                    whiteSpace: 'pre-wrap',
                },
            }}
        >
            <Typography variant="h6">{`FEEDBACK FROM ${
                parentName?.toUpperCase() || ''
            }`}</Typography>
            <Typography component="pre">{feedback}</Typography>
        </Box>
    )
}

const styles = {
    border: {
        marginBottom: '40px',
        border: '#DBDEE5 solid 1px',
    },
    blueBar: {
        backgroundColor: '#F7FAFF',
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& > h6': {
            fontWeight: '1000',
        },
    },
    textBoxDiv: {
        padding: '10px',
        '& > p': {
            lineHeight: '2.5',
        },
    },
}
export const CreateFeedback = ({ showBackButton }) => {
    const record = useRecordContext()
    const { save } = useSaveContext()
    const redirect = useRedirect()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const methods = useForm({
        defaultValues: record,
    })
    return (
        <FormProvider {...methods}>
            <Box sx={{ mx: 2 }}>
                <Box sx={styles.border}>
                    <Box sx={styles.blueBar}>
                        <Typography variant="h6">
                            INTEGRATION FEEDBACK
                        </Typography>
                        <IconButton
                            onClick={() => setOpen(!open)}
                            aria-label={`toggle feedback box ${
                                open ? 'closed' : 'open'
                            }`}
                            size="large"
                        >
                            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </Box>
                    {open && (
                        <Box sx={styles.textBoxDiv}>
                            <Typography>{`Send comments to ${record.discussion.partnerName} including changes required before publishing.`}</Typography>
                            <TextInput
                                fullWidth
                                helperText={false}
                                label="Add feedback..."
                                multiline
                                minRows={8}
                                InputProps={{ disableUnderline: true }}
                                source="discussion.feedback"
                                sx={{ width: '600px' }}
                            />
                        </Box>
                    )}
                </Box>
                <Divider />
                <Box sx={{ marginTop: '25px' }}>
                    {showBackButton && (
                        <Button
                            variant="text"
                            size="large"
                            onClick={() =>
                                navigate(`/integrations/${record.id}/edit`)
                            }
                        >
                            BACK
                        </Button>
                    )}

                    <Button
                        variant="contained"
                        size="large"
                        sx={{ mr: 2 }}
                        onClick={() =>
                            save(
                                {
                                    ...methods.getValues(),
                                    approvalStatus: 'Reviewed',
                                },
                                { onSuccess: () => redirect('/integrations') }
                            )
                        }
                    >
                        SEND FEEDBACK
                    </Button>
                    <Button
                        variant="outlined"
                        size="large"
                        onClick={() =>
                            save(
                                {
                                    ...methods.getValues(),
                                    approvalStatus: 'Approved',
                                },
                                { onSuccess: () => redirect('/integrations') }
                            )
                        }
                    >
                        APPROVE
                    </Button>
                </Box>
            </Box>
        </FormProvider>
    )
}
