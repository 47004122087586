import React, { useState } from 'react'
import { Edit, useRedirect } from 'react-admin'
import { Divider, Button, Box } from '@mui/material'
import { UpdateButton } from '../../../Wizard/WizardFormButtons'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'
import { connect } from 'react-redux'
import DeleteWithConfirmButton from '../../../react_admin/DeleteWithConfirmButton'

const editFormWrapperStyles = {
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    divider: {
        margin: '30px 0',
    },
    inputField: {
        width: '500px',
        display: 'flex',
    },
}

const IntegrationEditFormWrapper = ({
    dispatch,
    integration,
    record,
    children,
    page,
    cancelLabel,
    showDelete = false,
    disableSave,
    isParentOrg,
    integrationReleases,
    ...rest
}) => {
    const [initialValues, setValues] = useState(integration)

    const CancelButton = () => {
        const redirect = useRedirect()
        return (
            <Button
                onClick={() => {
                    redirect(`/integrations/${integration.id}/edit`)
                }}
            >
                {cancelLabel ? cancelLabel : 'CANCEL'}
            </Button>
        )
    }

    const SaveButton = ({ values, formContext }) => (
        <UpdateButton
            disabled={disableSave || !formContext.formState.isValid}
            values={values}
            resource={'integrations'}
            jsonFormValues={{}}
            record={integration}
            text="SAVE CHANGES"
        />
    )

    const SubmitButton = ({ values, submitting, invalid }) => (
        <UpdateButton
            disabled={
                disableSave ||
                submitting ||
                invalid ||
                !values.discussion?.enableSubmit
            }
            values={{
                ...values,
                approvalStatus: 'Submitted',
            }}
            resource={'integrations'}
            jsonFormValues={{}}
            record={integration}
            text="SAVE & SUBMIT"
        />
    )

    const Delete = () => (
        <DeleteWithConfirmButton
            id={integration.id}
            resource="integrations"
            label="Archive Integration"
            confirmContent="Are you sure you want to archive this integration?"
            toastContent="Integration archived"
            confirmTitle={`Archive ${integration.name}?`}
        />
    )

    // TODO: this button logic is too complicated and no longer makes
    // sense in this component. The buttons should instead live with the
    // appropriate form bodies, and the styling can stay here, using a
    // class name to identify the button bar.
    let Buttons

    if (
        (isParentOrg && integration.approvalStatus === 'Draft') ||
        page === 'provision'
    ) {
        // Just the cancel button if a client is looking at one of their partners' drafts,
        // or this is the provision connector page (provision connector page is never shown
        // for unapproved integrations)
        Buttons = () => (
            <Box sx={editFormWrapperStyles.buttonWrapper}>
                <div>
                    <CancelButton
                        id={integration.id}
                        cancelLabel={cancelLabel}
                    />
                </div>
                {showDelete && <Delete />}
            </Box>
        )
    } else if (!isParentOrg && integration.approvalStatus !== 'Approved') {
        // these are the buttons for a partner who is still editing a integration that has
        // not been approved. The have the option to submit the integration for review
        Buttons = (props) => {
            const form = useFormContext()
            const values = form.getValues()
            return (
                <Box sx={editFormWrapperStyles.buttonWrapper}>
                    <div>
                        <CancelButton />
                        <SaveButton
                            values={values}
                            formContext={form}
                            {...props}
                        />
                        <SubmitButton values={values} {...props} />
                    </div>
                    {showDelete && <Delete />}
                </Box>
            )
        }
    } else if (integration.approvalStatus === 'Approved') {
        // once an integration is approved, both the partner and the customer
        // can edit it, and the submit button goes away
        Buttons = (props) => {
            const form = useFormContext()
            const values = form.getValues()
            return (
                <Box sx={editFormWrapperStyles.buttonWrapper}>
                    <div>
                        <CancelButton />
                        <SaveButton
                            values={values}
                            formContext={form}
                            {...props}
                        />
                    </div>
                    {showDelete && <Delete />}
                </Box>
            )
        }
    } else {
        // the remaining conditions are when a customer is looking at their partner's
        // submission in review. In this case, the form should be read only and
        // the CreateFeedback component will be displayed by IntegrationEditWrapper
        // -- no buttons are needed here.
        Buttons = () => <div />
    }
    const methods = useForm({
        defaultValues: initialValues,
        mode: 'onChange',
    })

    return (
        <Edit
            {...rest}
            component="div"
            actions={null}
            sx={editFormWrapperStyles.container}
            resource={'integrations'}
        >
            <FormProvider {...methods}>
                <>
                    {children &&
                        React.Children.map(
                            children,
                            (child, index) =>
                                child &&
                                React.cloneElement(child, {
                                    setValues,
                                    index,
                                })
                        )}
                    <Divider sx={editFormWrapperStyles.divider} />
                    <Buttons />
                </>
            </FormProvider>
        </Edit>
    )
}

const mapStateToProps = (state) => ({
    isParentOrg: state.org.isParentOrg,
})
export default connect(mapStateToProps)(IntegrationEditFormWrapper)
