import React, { useState } from 'react'

// Have to do import from es5 for this to work when we build production build.
import { LazyLog } from 'react-lazylog'
import { Card, CardHeader, Button, Box } from '@mui/material'
import { API_URI } from '../appConfigs'
import { CloudDownload } from '@mui/icons-material'
import { RefreshButton, useDataProvider } from 'react-admin'

const DownloadButton = ({ record = {} }) => {
    const dataProvider = useDataProvider()

    const downloader = () => {
        dataProvider
            .GET_ONE_LOG('runs', { id: record.id })
            .then((res) => {
                //unless we use a library this is general advice for downloading data from api call
                //https://stackoverflow.com/questions/44656610/download-a-string-as-txt-file-in-react
                const element = document.createElement('a')
                element.setAttribute(
                    'href',
                    'data:text/plain;charset=utf-8,' +
                        encodeURIComponent(res.data)
                )
                element.download = `${record.id}.txt`
                document.body.appendChild(element) // Required for this to work in FireFox
                element.click()
            })
            .catch((err) => console.error(err))
    }

    return (
        <Button
            sx={{
                display: 'flex',
                fontSize: '17px',
                letterSpacing: '3px',
                marginTop: '3px',
            }}
            onClick={downloader}
        >
            <CloudDownload sx={{ margin: '0 10px 0 0' }} />
            DOWNLOAD
        </Button>
    )
}

const LogToolbar = ({ record, refreshKey, setRefreshKey }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            Log
            <Box sx={{ display: 'flex' }}>
                <RefreshButton
                    onClick={() => setRefreshKey(refreshKey + 1)}
                    sx={{
                        display: 'flex',
                        fontSize: '17px',
                        letterSpacing: '3px',
                        marginTop: '3px',
                    }}
                />
                <DownloadButton record={record} />
            </Box>
        </Box>
    )
}

export const LogField = ({ record, source, namespace }) => {
    const token = sessionStorage.getItem('token')
    const [refreshKey, setRefreshKey] = useState(0)

    return (
        <Card>
            <CardHeader
                title={
                    <LogToolbar
                        record={record}
                        refreshKey={refreshKey}
                        setRefreshKey={setRefreshKey}
                    />
                }
            />
            <div>
                <LazyLog
                    key={refreshKey}
                    style={{ overflowX: 'auto', overflowY: 'auto' }}
                    containerStyle={{ overflow: 'initial' }}
                    extraLines={1}
                    height={500}
                    width="auto"
                    url={`${API_URI}/runs/${record[source]}/log`}
                    fetchOptions={{
                        headers: {
                            'X-NAMESPACE': namespace,
                            Admin: true,
                            Authorization: `Bearer ${token}`,
                        },
                    }}
                    follow={true}
                    selectableLines={true}
                />
            </div>
        </Card>
    )
}
