import React, { useState, useEffect } from 'react'
import {
    Link,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Card,
    Box,
} from '@mui/material'
import Add from '@mui/icons-material/Add'
import { useRedirect } from 'react-admin'
import Logo from '../../../../imgs/logo.png'
// Todo update mui-icons to import this
import ExternalIntegrationIcon from '../../../../imgs/externalIntegrationIcon.png'

const integrationTypeSelectorStyles = {
    cardRoot: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#ffffff',
        boxShadow: 'none',
        borderRadius: '10px',
        margin: '40px 0 60px 0',
        height: '150px',
        width: '180px',
        border: '3px solid #DBDEE5',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#f7f7f7',
        },
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: '20px',
    },
    img: {
        objectFit: 'contain',
        width: '36px',
        height: '36px',
    },
    typeLabel: {
        fontFamily: 'RobotoCondensedBold',
    },
    description: {
        width: '160px',
        fontSize: '12px',
        fontFamily: 'RobotoCondensedRegular',
        textAlign: 'center',
        marginTop: 1,
        color: 'RGBA(0, 0, 0, 0.6)',
    },
}

const IntegrationTypeSelector = (props) => {
    const descriptionText = {
        Internal:
            'Pandium creates a release from your repository and runs these integrations on the platform.',
        External:
            'Create app tiles for deep-linking pre-existing integrations or 3rd party integrations.',
    }
    const { logo, label, handleSelect, selectedType } = props
    const [highlighted, setHighlighted] = useState(false)

    useEffect(() => setHighlighted(selectedType === label.toLowerCase()), [
        selectedType,
        label,
    ])

    const onclick = () => {
        handleSelect(label.toLowerCase())
    }

    return (
        <Card
            sx={(theme) => ({
                ...integrationTypeSelectorStyles.cardRoot,
                ...(highlighted
                    ? {
                          border: '3px solid',
                          borderColor: theme.PandiumColors.blue,
                      }
                    : {}),
            })}
            onClick={onclick}
            aria-label={label}
            role={'button'}
        >
            <Box sx={integrationTypeSelectorStyles.cardContent}>
                <Box
                    component="img"
                    alt={label}
                    src={logo}
                    sx={integrationTypeSelectorStyles.img}
                />
                <Box
                    component="span"
                    sx={integrationTypeSelectorStyles.typeLabel}
                >
                    {label}
                </Box>
                <Box sx={integrationTypeSelectorStyles.description}>
                    {' '}
                    {descriptionText[`${label}`]}
                </Box>
            </Box>
        </Card>
    )
}

const popupDialogStyles = {
    dialog: {
        root: {
            width: '629px',
            height: '400px',
        },
        paperWidthSm: {
            maxWidth: '628px',
        },
    },
    text: {
        fontSize: '15px',
        fontFamily: 'RobotoCondensedRegular',
        lineHeight: '20px',
        fontColor: '#666666',
    },
}

const PopupDialog = (props) => {
    const { onClose, open } = props
    const [selectedType, setSelectedType] = useState(null)
    const redirect = useRedirect()

    const handleSelect = (value) => {
        if (value) setSelectedType(value)
    }

    return (
        <Dialog
            sx={popupDialogStyles.dialog}
            open={open}
            onClose={() => {
                setSelectedType(null)
                onClose(null)
            }}
        >
            <DialogTitle>New Integration</DialogTitle>
            <DialogContent>
                <Box component="span" sx={popupDialogStyles.text}>
                    Select the type of integration you would like to create.
                    Learn more about{' '}
                    <Link
                        sx={{ color: '#1976d2' }}
                        href="https://docs.pandium.com/getting-started/key-terminology#integration"
                        target="_blank"
                    >
                        integration types.
                    </Link>
                </Box>
                <Grid
                    container
                    justifyContent={'space-around'}
                    alignItems={'center'}
                >
                    <Grid item>
                        <IntegrationTypeSelector
                            logo={Logo}
                            label={'Internal'}
                            handleSelect={handleSelect}
                            selectedType={selectedType}
                        />
                    </Grid>
                    <Grid item>
                        <IntegrationTypeSelector
                            logo={ExternalIntegrationIcon}
                            label={'External'}
                            handleSelect={handleSelect}
                            selectedType={selectedType}
                        />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        textAlign: 'end',
                        margin: '24px',
                    }}
                >
                    <Button
                        sx={{ mr: 1 }}
                        onClick={() => {
                            setSelectedType(null)
                            onClose(null)
                        }}
                    >
                        {' '}
                        Cancel{' '}
                    </Button>
                    <Button
                        aria-label={'createIntegrationButton'}
                        variant="action"
                        disabled={!selectedType}
                        onClick={() =>
                            redirect(`/integrations/create/${selectedType}`)
                        }
                    >
                        CREATE
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

/**
 * New Button for create Integration which doesn't go to wizardForm *
 */

export default ({ startCreateView, externalOnly }) => {
    const [openPopup, setOpenPopup] = useState(startCreateView)
    const redirect = useRedirect()
    const handleClickOpen = () => {
        setOpenPopup(true)
    }

    const handleClose = () => {
        setOpenPopup(false)
    }

    return (
        <div>
            <Button
                variant="action"
                onClick={
                    externalOnly
                        ? () => redirect(`/integrations/create/external`)
                        : handleClickOpen
                }
            >
                <Add sx={{ margin: '0 6px' }} />
                Create
            </Button>
            <PopupDialog open={openPopup} onClose={handleClose} />
        </div>
    )
}
