import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    DialogContentText,
    Box,
    IconButton,
} from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import Delete from '@mui/icons-material/Delete'
import Add from '@mui/icons-material/Add'
import {
    useForm,
    FormProvider,
    Controller,
    useFieldArray,
    useFormContext,
} from 'react-hook-form'
import { TextInput, SelectInput, useUpdate, BooleanInput } from 'react-admin'
import SyncButton from './SyncButton'
import { required } from './inputValidators'
import { useNavigate } from 'react-router-dom'

const DirectionToggle = ({ name }) => {
    const { control } = useFormContext()

    return (
        <Controller
            name={name}
            control={control}
            defaultValue=">"
            render={({ field: { onChange, value } }) => {
                // Toggle between ">" and "<"
                const handleToggle = () => {
                    onChange(value === '>' ? '<' : '>')
                }

                return (
                    <Button
                        variant="outlined"
                        onClick={handleToggle}
                        sx={{
                            height: 40,
                            minWidth: 50,
                            margin: '0 0 18px',
                        }}
                    >
                        {value === '>' ? (
                            <ArrowForwardIcon />
                        ) : (
                            <ArrowBackIcon />
                        )}
                    </Button>
                )
            }}
        />
    )
}

/**
 * FlowsInput
 *
 * A custom input component for handling an array of flows.
 * Each flow consists of three fields: aObj, direction, and bObj.
 * Users can add or remove flows.
 *
 * Props:
 * - name: string (optional) the field name; defaults to "flows"
 * - label: string (optional) the label displayed above the flows input; defaults to "Configure Flows:"
 */
const FlowsInput = ({
    name = 'flows',
    label = 'Configure Flows:',
    connectorA,
    connectorB,
}) => {
    // useFormContext provides access to the form's methods.
    const { control } = useFormContext()

    // useFieldArray manages the flows array.
    const { fields, append, remove } = useFieldArray({
        control,
        name,
    })

    return (
        <Box mt={2}>
            <DialogContentText>{label}</DialogContentText>
            {fields.map((item, index) => (
                <Box
                    key={item.id}
                    display="flex"
                    gap={1}
                    alignItems="center"
                    mb={1}
                >
                    <TextInput
                        label={`${connectorA} Object`}
                        source={`${name}[${index}].${connectorA}`}
                        variant="filled"
                        name={`${name}[${index}].${connectorA}`}
                    />
                    <DirectionToggle name={`${name}[${index}].direction`} />
                    <TextInput
                        label={`${connectorB} Object`}
                        source={`${name}[${index}].${connectorB}`}
                        variant="filled"
                        name={`${name}[${index}].${connectorB}`}
                    />
                    <IconButton
                        onClick={() => remove(index)}
                        sx={{ margin: '0 0 18px' }}
                        color="warning"
                    >
                        <Delete />
                    </IconButton>
                </Box>
            ))}
            <IconButton
                onClick={() =>
                    append({
                        [connectorA]: '',
                        direction: '>',
                        [connectorB]: '',
                    })
                }
                color="primary"
            >
                <Add />
            </IconButton>
        </Box>
    )
}

export const getRepoType = (integration) => {
    const repoURL = integration?.repositoryUrl || ''
    if (repoURL.includes('github')) {
        return 'github'
    } else if (repoURL.includes('gitlab')) {
        return 'gitlab'
    } else if (repoURL.includes('bitbucket')) {
        return 'bitbucket'
    } else if (repoURL.includes('azure')) {
        return 'azure-devops'
    } else {
        return 'github'
    }
}

const SetupRepoDialog = ({
    disabled,
    integration,
    sourceControlTenant,
    isParentOrg,
}) => {
    const [open, setOpen] = useState(false)
    const [step, setStep] = useState(0)
    const navigate = useNavigate()
    const connectorA = integration.connectors[0]?.name
    const connectorB = integration.connectors[1]?.name

    const methods = useForm({
        defaultValues: {
            repoPath: integration?.repositoryPath,
            integrationLanguage: 'python',
            includeAPIClients: false,
            includeCodeGen: false,
            flows: [{ [connectorA]: '', direction: '>', [connectorB]: '' }],
        },
        mode: 'onBlur',
    })

    // Watch for the language value to detect if Typescript is selected.
    const { watch, getValues, setValue } = methods
    const language = watch('integrationLanguage')
    const isTypeScript = language === 'node-ts'

    const [repoPathUpdate] = useUpdate('integrations', {
        id: integration.id,
        data: { repositoryPath: getValues('repoPath') },
        previousData: integration,
    })

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setStep(0)
    }

    const handleNext = () => {
        setStep(1)
    }

    const handleBack = () => {
        setStep(0)
    }

    const handleSubmit = () => {
        // If bool is false, clear flows
        if (!getValues('includeCodeGen')) {
            setValue('flows', [])
        }
        repoPathUpdate()
        navigate(isParentOrg ? '/account?tab=5' : '/account?tab=2')
    }

    const renderStepContent = () => {
        if (step === 0) {
            return (
                <>
                    <DialogContentText
                        sx={{
                            fontFamily: 'RobotoCondensedRegular',
                            fontSize: '16px',
                        }}
                    >
                        Fill out the form and we will push a pandium.yaml and
                        lib file to your repository
                    </DialogContentText>
                    <TextInput
                        label="Repository Path"
                        source="repoPath"
                        variant="filled"
                        sx={{
                            width: '100%',
                            margin: '0',
                        }}
                        placeholder={integration?.repositoryPath}
                        validate={[required]}
                        name="repoPath"
                    />
                    <SelectInput
                        label="Language"
                        source="integrationLanguage"
                        sx={{
                            width: '100%',
                            margin: '0',
                        }}
                        choices={[
                            { id: 'python', name: 'Python' },
                            { id: 'ruby', name: 'Ruby' },
                            { id: 'node', name: 'Node-JS' },
                            { id: 'node-ts', name: 'Typescript' },
                            { id: 'clojure', name: 'Clojure' },
                            { id: 'php', name: 'PHP' },
                            { id: 'java', name: 'Java' },
                            { id: '.net', name: 'C#' },
                            { id: 'go', name: 'Golang' },
                            { id: 'kotlin', name: 'Kotlin' },
                        ]}
                        validate={[required]}
                        name="integrationLanguage"
                    />
                </>
            )
        } else {
            // Step 1: Additional options.
            return (
                <Box>
                    <DialogContentText
                        sx={{
                            fontFamily: 'RobotoCondensedRegular',
                            fontSize: '16px',
                            paddingBottom: '8px',
                        }}
                    >
                        In addition to scaffolding code that will accelerate
                        your integration development, we offer the ability to
                        include relevant API Clients and AI generated
                        integration logic in your repository.
                    </DialogContentText>
                    <BooleanInput
                        source="includeAPIClients"
                        label="Include API Clients"
                        name="includeAPIClients"
                        disabled
                    />
                    <BooleanInput
                        source="includeCodeGen"
                        label="Include CodeGen"
                        name="includeCodeGen"
                        disabled
                    />
                    {watch('includeCodeGen') && watch('includeAPIClients') && (
                        <FlowsInput
                            connectorA={connectorA}
                            connectorB={connectorB}
                        />
                    )}
                </Box>
            )
        }
    }

    return (
        <Box sx={{ display: 'inline' }}>
            <Button onClick={handleClickOpen} disabled={disabled}>
                Setup Integration Repo
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                sx={{
                    '& .MuiDialog-paper': {
                        padding: '10px',
                        overflowY: 'visible',
                        maxWidth: '500px',
                    },
                }}
            >
                <FormProvider {...methods}>
                    <DialogTitle sx={{ padding: '16px 16px 0 16px' }}>
                        Add Pandium files to your repo
                    </DialogTitle>
                    <DialogContent sx={{ padding: '8px 16px' }}>
                        {renderStepContent()}
                    </DialogContent>
                    <DialogActions>
                        {step === 1 && (
                            <Button onClick={handleBack}>Back</Button>
                        )}
                        {step === 0 && isTypeScript ? (
                            <Button
                                className="filledButton"
                                onClick={handleNext}
                            >
                                Next
                            </Button>
                        ) : (
                            <>
                                <Button onClick={handleClose}>Cancel</Button>
                                <SyncButton
                                    key="sync"
                                    label="SETUP REPO"
                                    record={sourceControlTenant}
                                    onClick={handleSubmit}
                                    syncType={'normal'}
                                    payload={{
                                        repoType: getRepoType(integration),
                                        buildIntegration: integration.name,
                                        buildIntegrationID: integration.id,
                                        setup: true,
                                        repoPath: getValues('repoPath'),
                                        integrationLanguage: getValues(
                                            'integrationLanguage'
                                        ),
                                        includeCodeGen: getValues(
                                            'includeCodeGen'
                                        ),
                                        includeAPIClients: getValues(
                                            'includeAPIClients'
                                        ),
                                        flows: getValues('flows'),
                                    }}
                                    headers={{
                                        'X-Build-Integration-Name':
                                            integration.name,
                                        'X-Build-Integration-Id':
                                            integration.id,
                                        'X-Release-Tag': `${getValues(
                                            'integrationLanguage'
                                        )}--init`,
                                    }}
                                />
                            </>
                        )}
                    </DialogActions>
                </FormProvider>
            </Dialog>
        </Box>
    )
}

export default SetupRepoDialog
