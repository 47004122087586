import React, { useState, useEffect, useMemo } from 'react'
import {
    TextField as RATextField,
    useGetList,
    useNotify,
    Confirm,
    useRecordContext,
    useDataProvider,
} from 'react-admin'
import { useMutation } from 'react-query'
import DeleteButton from '../../DeleteButton'
import BaseDatagrid from '../../react_admin/BaseDatagrid'
import { Tooltip } from '@mui/material'
import { Grid, Button } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { get, isEmpty } from 'lodash-es'
import { connect } from 'react-redux'

const useDeleteTooltipStyles = makeStyles({
    tooltip: {
        fontFamily: 'Roboto',
        textAlign: 'center',
        width: '165px',
        height: 'auto',
        fontSize: '12px',
        backgroundColor: 'rgba(0,0,0,0.87)',
    },
})

const EnabledField = ({ source }) => {
    const record = useRecordContext()
    return <span>{record[source] ? 'Active' : 'Inactive'}</span>
}

const FullNameField = () => {
    const record = useRecordContext()
    const fullName =
        record['firstName'] && record['lastName']
            ? record['firstName'] + ' ' + record['lastName']
            : ''
    return <span> {fullName} </span>
}

const DeleteUserField = ({ namespace, userId }) => {
    const record = useRecordContext()
    // Create a map of all primary users in a namespace
    let { data, isLoading } = useGetList(
        'organizations',
        {},
        {},
        { namespace: namespace }
    )

    const primaryUsers = useMemo(() => {
        if (!isEmpty(data)) {
            let primaryUserToOrg = {}
            for (let org in data) {
                primaryUserToOrg[data[org]?.primaryUser?.id] = data[org]?.id
            }
            return primaryUserToOrg
        }
    }, [data])

    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        if (!isLoading && !isEmpty(data)) {
            setIsDisabled(
                primaryUsers[record.id] || record.id === userId ? true : false
            )
        }
    }, [isLoading, data, record, primaryUsers, userId])
    let showTooltip = isDisabled ? true : false

    const classes = useDeleteTooltipStyles()

    return showTooltip ? (
        <Tooltip
            classes={{ tooltip: classes.tooltip }}
            placement="left"
            title={
                record.id === userId
                    ? 'See a primary admin to delete your account.'
                    : 'Primary admins for an organization cannot be deleted.'
            }
        >
            <span>
                <DeleteButton
                    record={record}
                    resource="users"
                    disabled={isLoading || isDisabled}
                >
                    Delete
                </DeleteButton>
            </span>
        </Tooltip>
    ) : (
        <DeleteButton
            record={record}
            resource={'users'}
            disabled={isLoading || isDisabled}
        >
            Delete
        </DeleteButton>
    )
}

const ResetPasswordField = () => {
    const record = useRecordContext()
    const notify = useNotify()
    const dataProvider = useDataProvider()
    const { mutate: updateUserPassword } = useMutation(
        () =>
            dataProvider.UPDATE_USER_WITH_VERIFY('users', {
                id: record.id,
                data: {
                    action: 'trigger-reset-password',
                    userId: record.id,
                },
            }),
        {
            onSuccess: () => {
                setOpenConfirm(false)
                notify('Password Reset Email Sent!')
            },
            onError: (error) => {
                setOpenConfirm(false)
                notify(
                    'Password reset email failed to send: ' + error['message'],
                    {
                        type: 'warning',
                    }
                )
                console.debug('Error: ', get(error, 'message', error))
            },
        }
    )

    const [openConfirm, setOpenConfirm] = useState(false)
    return (
        <Grid>
            <Button onClick={() => setOpenConfirm(true)}>Reset password</Button>
            <Confirm
                isOpen={openConfirm}
                title="Reset Password"
                content="Are you sure you want to reset this password?"
                onConfirm={updateUserPassword}
                onClose={() => setOpenConfirm(false)}
                confirmColor={'#626FFC'}
            />
        </Grid>
    )
}

const mapStateToProps = (state) => ({ isParentOrg: state.org.isParentOrg })

export default connect(mapStateToProps)((props) => {
    return (
        <BaseDatagrid bulkActionButtons={false}>
            <FullNameField source="firstName" label="Name" />
            <RATextField source="email" />
            <RATextField source="role" />
            <RATextField source="organization" />
            <EnabledField source="enabled" />
            <DeleteUserField
                namespace={props.namespace}
                userId={props.userId}
            />
            {props.isParentOrg ? <ResetPasswordField /> : <></>}
        </BaseDatagrid>
    )
})
