import React from 'react'
import {
    TextField,
    TextInput,
    ListContextProvider,
    useListController,
    useNotify,
    useDelete,
    useRefresh,
    TopToolbar,
    useRecordContext,
    required,
} from 'react-admin'
import BaseDialog from '../../BaseDialog'
import { Typography, Button, CircularProgress, Box } from '@mui/material'
import { isEmpty } from 'lodash-es'
import { useSelector } from 'react-redux'
import BaseDatagrid from '../../react_admin/BaseDatagrid'
import ListPagination from '../List/ListPagination'
import { LABELS_PER_PAGE } from '../../../appConfigs'
import { EmptyState } from '../../react_admin/EmptyState'
import ColorPicker from '../themeConfig/ColorPicker'
import { showHideStyles } from './CategoriesList'
import { UpdateOneButton } from './CategoriesList'
import { CreateCategoryButton } from './CategoriesList'

const colorFieldStyles = (hex) => ({
    colorBox: {
        height: '25px',
        width: '25px',
        backgroundColor: hex,
        marginRight: '15px',
        borderRadius: '4px',
    },
})

const EditFlagField = ({ theme }) => {
    const record = useRecordContext()
    const notify = useNotify()
    const refresh = useRefresh()
    const [deleteOne, { deleteLoading }] = useDelete('integrationlabels', {
        id: record?.id,
        previousDate: record,
        onSuccess: () => {
            refresh()
            notify('Deleted Successfully!')
        },
    })

    const dialogContentFields = (
        <>
            <TextInput
                label="Name"
                source="name"
                variant="filled"
                validate={required()}
                sx={(theme) => showHideStyles(theme.PandiumColors).inputField}
            />
            <TextInput
                label="Description"
                source="description"
                variant="filled"
                sx={(theme) => showHideStyles(theme.PandiumColors).inputField}
            />
            <ColorPicker
                source="flagColor"
                label="Flag color"
                modifiers={{
                    preventOverflow: { escapeWithReference: true },
                }}
                placement="right"
                theme={theme}
            />
        </>
    )
    if (deleteLoading) {
        return <CircularProgress />
    }

    return (
        <>
            <Button
                sx={(theme) => showHideStyles(theme.PandiumColors).button}
                onClick={() => deleteOne()}
            >
                Remove{' '}
            </Button>
            <BaseDialog
                SubmitButton={UpdateOneButton}
                initialValues={record}
                openDialogButtonText="Edit"
                dialogTitle={`Edit ${record.labelType}`}
                dialogContentText=""
                dialogContentFields={dialogContentFields}
                closeDialogButtonText="Cancel"
            />
        </>
    )
}

export default ({ title, labelType }) => {
    const theme1 = useSelector((state) => state.org.theme)

    const controllerProps = useListController({
        basePath: '/integrationlabels',
        resource: 'integrationlabels',
        perPage: LABELS_PER_PAGE,
        filter: { labelType: 'FLAG' },
    })

    const records = controllerProps.data

    const createFlagDialog = (
        <>
            <TextInput
                label="Type"
                source="label_type"
                validate={required()}
                sx={(theme) => showHideStyles(theme.PandiumColors).hiddenField}
                defaultValue="Flag"
            />
            <TextInput
                label="Name"
                source="name"
                validate={required()}
                variant="filled"
                sx={(theme) => showHideStyles(theme.PandiumColors).inputField}
            />
            <TextInput
                label="Description"
                source="description"
                variant="filled"
                sx={(theme) => showHideStyles(theme.PandiumColors).inputField}
            />
            <ColorPicker
                source="flagColor"
                label="Flag color"
                modifiers={{
                    preventOverflow: { escapeWithReference: true },
                }}
                placement="right"
            />
        </>
    )

    const ColorField = () => {
        const record = useRecordContext()
        const hex = record.flagColor || theme1?.configs?.palette?.primary?.main
        return (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={colorFieldStyles(hex).colorBox} />
                <div>
                    {record.flagColor?.replace('#', '')?.toUpperCase() ||
                        theme1?.configs?.palette?.primary?.main
                            ?.replace('#', '')
                            ?.toUpperCase()}
                </div>
            </span>
        )
    }

    return (
        <>
            <TopToolbar>
                <Typography
                    variant="h5"
                    sx={(theme) =>
                        showHideStyles(theme.PandiumColors).greyTitle
                    }
                >
                    {title}
                </Typography>
                <BaseDialog
                    SubmitButton={CreateCategoryButton}
                    initialValues={{ label_type: labelType }}
                    openDialogButtonText="create"
                    dialogTitle={`Create New ${labelType}`}
                    dialogLauncherVariant="action"
                    dialogContentText=""
                    dialogContentFields={createFlagDialog}
                    closeDialogButtonText="Cancel"
                />
            </TopToolbar>
            <ListContextProvider value={controllerProps}>
                {isEmpty(records) ? (
                    <EmptyState
                        emptyStateText={'No integration flags to show'}
                    />
                ) : (
                    <BaseDatagrid bulkActionButtons={false}>
                        <TextField
                            source="name"
                            label={labelType}
                            sortable={false}
                        />
                        <TextField
                            label="Description"
                            source="description"
                            sortable={false}
                        />
                        <ColorField label="Color" />
                        <EditFlagField
                            label="Actions"
                            sortable={false}
                            theme={theme1}
                        />
                    </BaseDatagrid>
                )}
                <ListPagination />
            </ListContextProvider>
        </>
    )
}
