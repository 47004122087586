import React, { useState } from 'react'
import { Divider, Button, Typography, Box } from '@mui/material'
import { JsonForms } from '@jsonforms/react'
import { materialRenderers } from '@jsonforms/material-renderers'
import { connect } from 'react-redux'
import { useUpdate, useGetOne, Form, useRedirect, useNotify } from 'react-admin'
import { updateOrgAction } from '../customActions'
import BooleanWithLink from './jsonFormComponents/BooleanWithLink'
import BooleanWithLinkTester from './jsonFormComponents/BooleanWithLinkTester'
import {
    CustomStringInput,
    CustomStringInputTester,
} from './jsonFormComponents/CustomStringInput'

const PartnerIntake = ({
    topLogo,
    intakeForm,
    parentName,
    orgId,
    updateOrg,
}) => {
    const [update] = useUpdate()
    const { data } = useGetOne('organizations', { id: orgId })
    const [answers, setAnswers] = useState({})
    const [errors, setErrors] = useState([])
    const notify = useNotify()
    const redirect = useRedirect()

    if (!orgId || !parentName || !intakeForm || !topLogo) {
        return (
            <Box
                sx={{
                    paddingLeft: '71px',
                    paddingRight: '74px',
                    width: '100%',
                    color: 'red',
                    backgroundColor: 'white',
                }}
            >
                <Typography variant="h4">Error</Typography>
                <Typography>
                    Partner intake form not found. Please contact your
                    administrator.
                </Typography>
            </Box>
        )
    }

    const { schema, uischema } = intakeForm

    const onClick = () => {
        updateOrg({ approvalStatus: 'Approved' })
        update(
            'organizations',
            {
                id: orgId,
                data: {
                    ...data,
                    partnerIntakeForm: {
                        schema: schema,
                        uischema: uischema,
                        answers: answers,
                    },
                    approvalStatus: 'Approved',
                },
                previousData: data,
            },
            {
                onSuccess: () => {
                    updateOrg({ approvalStatus: 'Approved' })
                    notify('Organization Approved!')
                    redirect('/integrations')
                    window.location.reload() //need a hard reload in order to get out of the intake form view
                },
                onError: (error) => {
                    console.debug(error)
                    updateOrg({ approvalStatus: 'Pending' })
                },
            }
        )
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                backgroundColor: 'black',
            }}
        >
            <span
                style={{
                    height: '35px',
                }}
            >
                <img src={topLogo} alt={parentName} height="35px" />
            </span>

            <Box
                sx={{
                    alignSelf: 'center',
                    width: '725px',
                    backgroundColor: 'white',
                    padding: '40px 0px 20px 0px',
                    marginTop: '140px',
                    marginBottom: '500px',
                    borderRadius: '6px',
                    minHeight: '600px',
                    '& hr': {
                        margin: '20px 0px 20px 0px',
                    },
                    '& button': {
                        width: '163px',
                    },
                    '& h4': {
                        fontWeight: 'bold',
                    },
                }}
            >
                <Box
                    sx={{
                        marginLeft: '71px',
                        marginRight: '74px',
                    }}
                >
                    <Typography variant="h4">Partner Details</Typography>
                    <Typography>
                        Please fill out the following information
                    </Typography>
                </Box>
                <Divider />

                <Form>
                    <Box
                        sx={{
                            marginLeft: '71px',
                            marginRight: '74px',
                        }}
                    >
                        <JsonForms
                            data={answers}
                            schema={schema}
                            uischema={uischema}
                            renderers={[
                                ...materialRenderers,
                                {
                                    tester: CustomStringInputTester,
                                    renderer: CustomStringInput,
                                },
                                {
                                    tester: BooleanWithLinkTester,
                                    renderer: BooleanWithLink,
                                },
                            ]}
                            onChange={({ errors, data }) => {
                                setAnswers(data)
                                setErrors(errors)
                            }}
                            validationMode="ValidateAndHide"
                        />
                        <Divider />
                        <Button
                            disabled={!!errors.length}
                            variant="contained"
                            onClick={onClick}
                        >
                            Submit
                        </Button>
                    </Box>
                </Form>
            </Box>
        </Box>
    )
}

const mapStateToProps = (state) => {
    return {
        topLogo: state.org.topLogo,
        intakeForm: state.org.intakeForm,
        parentName: state.org.parentName,
        orgId: state.org.orgId,
    }
}
const mapDispatchToProps = (dispatch) => ({
    updateOrg: (org) => dispatch(updateOrgAction(org)),
})
export default connect(mapStateToProps, mapDispatchToProps)(PartnerIntake)
